import adapter from 'webrtc-adapter'
import Janus from 'janus-gateway'
import StreamCanvasBuilder from "@modules/webinars/plugins/streamCanvasBuilder/streamCanvasBuilder";


class WsJanusStreamer {

    constructor(server) {
        console.log('[WsJanusStreamer:constructor] Building canvas builder')
        this._canvasBuilder = new StreamCanvasBuilder()
        console.log('[WsJanusStreamer:constructor] Canvas builder constructed' , this._canvasBuilder)
        this._JANUS_URL = server || ['ws://localhost:8188', 'http://localhost:8088/janus']
        this._janus = null
        this._plugin = null
        this._opaqueId = Janus.randomString(12)
        this._canvasCascadeType = 'Default'

        this._userId = null
        this._roomId = null

        this._myid = null
        this._mypvtid = null

        this._streamCallback = null
        this._canvasCallback = null
        this._joinCallback = null
        this._fps = 36


        this._sources = []
        this._audio = null
        this._canvas = null

        Janus.init({
            debug: true,
            dependencies: Janus.useDefaultDependencies({ adapter: adapter }),
            callback: () => {
                console.log("Janus.init success")
            }
        })

    }


    setSources(sources) {
        this._sources = [...sources]
        console.log('Yanus new sources' , sources)
        for (let source of this._sources) {
            if (!source.dom.srcObject) {
                source.dom.srcObject = source.stream
                console.log('Starting new video', source.stream)
            }
        }
    }
    setCanvasCascadeType(type) {
        this._canvasCascadeType = type
    }

    async startStream({token, roomId, userId, sources, audio, canvas, streamCallback, canvasCallback, joinCallback}) {
        this._userId = userId
        this._roomId = roomId

        this._sources = sources
        this._audio = audio
        this._canvas = canvas

        this._streamCallback = streamCallback
        this._canvasCallback = canvasCallback
        this._joinCallback = joinCallback

        try {

            this._janus = new Janus({
                server: this._JANUS_URL,
                token: token,
                success: () => {
                    this.attachStreamingPlugin()
                    console.log('Janus Success')
                },
                error: (error) => {
                    console.error('Failed to connect to Janus server', error)
                    // onError('Failed to connect to janus server', error)
                },
                destroyed: () => {
                    window.location.reload()
                    console.log("destroyed")
                }
            })
        } catch (error) {
            // onError('Error accessing camera:', error)
            console.log('Error accessing camera:', error)
        }
    }

    onLocalStream(track) {
        console.log("onLocalStream", track)

        if (track.kind === "audio") {
            // New audio track: create a stream out of it, and use a hidden <audio> element
            // let audioStream = new MediaStream([track]);
            // log("Created remote audio stream:", audioStream);
            // Janus.attachMediaStream(audio.value, stream);
            // audio.value.srcObject = audioStream
        } else {
            // New video track: create a stream out of it
            let videoStream = new MediaStream([track]);
            console.log("Created local video stream:", videoStream);
            this._canvasCallback(videoStream)
        }
    }

    onRemoteStream(track, id , display , on) {
        console.log('onRemoteStream' , track, id , display , on)
        const isAudio = track.kind === "audio"
        let stream = new MediaStream([track]);
        id += isAudio ? 'audio' : 'video'
        this._streamCallback({stream , id , display , on, isAudio})
    }

    attachStreamingPlugin() {
        this._janus.attach({
            plugin: "janus.plugin.videoroom",
            opaqueId: this._opaqueId,
            success: (pluginHandle) => {
                console.log("Publisher plugin attached!");
                console.log(pluginHandle);
                this._plugin = pluginHandle
                pluginHandle.ondata = (data) => {
                    console.log('I HAVE DATA' , data)
                }

                let request = {
                    request: "create",
                    bitrate: 8192000, // 4 Mbps for better quality
                    // bitrate: 4096000,
                    fir_freq: 10,
                    publishers: 6,
                    room: this._hashCode(this._roomId),
                    id: this._hashCode(this._userId) + Date.now(),
                    pin: this._roomId,
                    notify_joining : true
                };

                this._plugin.send({
                    message: request ,
                    success : () => {
                        console.log('I HAVE CREATED ROOM OR IT ALREADY EXISTS')
                        let request = {
                            request: "join",
                            room: this._hashCode(this._roomId),
                            ptype: "publisher",
                            id: this._hashCode(this._userId) + Date.now(),
                            display: "broadcaster",
                            pin: this._roomId
                        };

                        this._plugin.send({ message: request });
                    },
                    error : (error) => {
                        console.log('I HAVE PROBLEMS', error)
                    }
                });
            },
            onmessage: async (message, jsep) => {
                console.log("onmessage", message, jsep)

                if (message.error ) {
                    console.log('I HAVE ERRORR ' , message.error)
                }

                if (message.videoroom === "joined") {
                    // Joined successfully, create SDP Offer with our stream
                    // let fps = 30
                    console.log("Joined room! Creating offer...");

                    let context = this._canvas.getContext('2d', { alpha: true })
                    context.imageSmoothingEnabled = true;
                    context.imageSmoothingQuality = 'high';

                    for (let index in this._sources) {
                        this._sources[index].dom.srcObject = this._sources[index].stream
                    }

                    const draw = () => {
                        this._canvasBuilder.drawCanvas(context,this._sources , this._canvas, this._canvasCascadeType)
                        setTimeout(draw, 1000 / this._fps)
                        // requestAnimationFrame(draw);
                    }
                    draw()

                    let newStream = this._canvas.captureStream(this._fps)

                    let mediaConfig = []
                    // let body = { audio: true, video: true }
                    // plugin.value.send({ message: body })

                    // TODO: In case of no stream  ,
                    if (!this._sources.length) {
                        mediaConfig = [
                            {type: 'audio', capture: false, recv: false, add: false},
                            {type: 'video', capture: false, recv: false, add: false},
                        ]
                    } else {
                        console.log("canvasStream.getAudioTracks()[0]", this._audio.stream.getAudioTracks()[0])
                        console.log("canvasStream.getVideoTracks()[0]", newStream.getVideoTracks()[0])

                        mediaConfig.push({
                            type: 'audio',
                            capture: true,//this._audio.stream.getAudioTracks()[0],
                            recv: false,
                            add: true
                        })

                        if (newStream.getVideoTracks().length > 0)
                            mediaConfig.push({
                                type: 'video',
                                capture: newStream.getVideoTracks()[0],
                                recv: false,
                                add: true
                            })
                    }

                    console.log("Media Configuration for Publisher set! ->");
                    console.log(mediaConfig);

                    this._plugin.createOffer({
                        tracks: mediaConfig,
                        success: (sdpAnswer) => {
                            // SDP Offer answered, publish our stream
                            console.log("Offer answered! Start publishing...")
                            let publish = {
                                request: "configure",
                                audio: true,
                                video: true,
                                data: true
                            };
                            this._plugin.send({message: publish, jsep: sdpAnswer})
                        },
                        error: (error) => {
                            console.log("createOffer error", error)
                        }
                    })
                }

                else if (message.videoroom === "destroyed") {
                    // Room has been destroyed, time to leave...
                    console.log("Room destroyed! Time to leave...")
                }

                if (message.unpublished) {
                    // We've gotten unpublished (disconnected, maybe?), leaving...
                    if (message.unpublished === "ok") {
                        console.log("We've gotten disconnected, hanging up...")
                        this._plugin.hangup();
                    }
                }

                if (jsep) {
                    console.log("Handling remote JSEP SDP")
                    console.log(jsep)
                    this._plugin.handleRemoteJsep({ jsep: jsep })
                }

                if (message.videoroom === "joined") {
                    console.log('message.videoroom === "joined" SUCCESS JOIN')
                    this._myid = message["id"];
                    this._mypvtid = message["private_id"];
                    Janus.log("Successfully joined room " + message["room"] + " with ID " + message["id"]);

                    if (message["publishers"]) {
                        let list = message["publishers"];
                        Janus.debug("Got a list of available publishers/feeds:", list);
                        for(let f in list) {
                            if(list[f]["dummy"])
                                continue;
                            let id = list[f]["id"];
                            let streams = list[f]["streams"];
                            let display = list[f]["display"];
                            for(let i in streams) {
                                let stream = streams[i];
                                stream["id"] = id;
                                stream["display"] = display;
                            }
                            console.log("  >> [" + id + "] " + display + ":", streams);
                            this.newRemoteFeed(id, display, streams);
                        }
                    }
                }

                if (message.videoroom === "event") {
                    console.log("videoroom === event")


                    if (message.error ) {
                        console.log('I HAVE ERRORR ' , message.error)
                    }


                    if (message.joining && message.joining.id) {

                        this._joinCallback(message.joining)
                    }

                    if (message["publishers"]) {
                        let list = message["publishers"];
                        console.log("Got a list of available publishers/feeds:", list);
                        for(let f in list) {
                            if(list[f]["dummy"])
                                continue;

                            let id = list[f]["id"];
                            let streams = list[f]["streams"];
                            let display = list[f]["display"];

                            for(let i in streams) {
                                let stream = streams[i];

                                stream["id"] = id;
                                stream["display"] = display;
                            }
                            console.log("  >> [" + id + "] " + display + ":", streams);
                            this.newRemoteFeed(id, display, streams);
                        }
                    }
                }
            },
            onlocaltrack: (track, on) => {

                console.log("Successfully published local track: ", on, track);
                // if(!on) {
                //     if(track.kind === "video") {
                //         delete localStreamVideos.value[track["id"]]
                //
                //         isStreaming.value = Object.keys(localStreamVideos.value).length > 0
                //     }
                //
                //     return
                // }

                this.onLocalStream(track)
            },
            error: (err) => {
                console.log("Publish: Janus VideoRoom Plugin Error!", true);
                console.log(err, true);
            },
            oncleanup: () => {
                // PeerConnection with the plugin closed, clean the UI
                // The plugin handle is still valid so we can create a new one
                console.log("oncleanup")
                // onCleanup()
            },
            ondetached: () => {
                // Connection with the plugin closed, get rid of its features
                // The plugin handle is not valid anymore
                console.log("ondetached plugin")
            }
        })
    }

    newRemoteFeed(id, display, streams) {
        // A new feed has been published, create a new plugin handle and attach to it as a subscriber
        let subscriberPlugin = null;
        let _this = this

        console.log('=>> NEW REMOTE FEED <<=')
        this._janus.attach({
            plugin: "janus.plugin.videoroom",
            opaqueId: _this._opaqueId,
            success: function(pluginHandle) {
                subscriberPlugin = pluginHandle;
                subscriberPlugin.remoteTracks = {};
                subscriberPlugin.remoteVideos = 0;
                subscriberPlugin.simulcastStarted = false;
                subscriberPlugin.svcStarted = false;
                Janus.log("Plugin attached! (" + subscriberPlugin.getPlugin() + ", id=" + subscriberPlugin.getId() + ")");
                Janus.log("  -- This is a subscriber");
                // Prepare the streams to subscribe to, as an array: we have the list of
                // streams the feed is publishing, so we can choose what to pick or skip
                let subscription = [];
                for(let i in streams) {
                    let stream = streams[i];
                    // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                    if(stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                        ((stream.codec === "vp9" && !Janus.safariVp9) || (stream.codec === "vp8" && !Janus.safariVp8))) {
                        console.log("Publisher is using " + stream.codec.toUpperCase +
                            ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
                        continue;
                    }
                    subscription.push({
                        feed: stream.id,  // This is mandatory
                        mid: stream.mid   // This is optional (all streams, if missing)
                    });
                    // FIXME Right now, this is always the same feed: in the future, it won't
                    subscriberPlugin.rfid = stream.id;
                    subscriberPlugin.rfdisplay = stream.display;
                }
                // We wait for the plugin to send us an offer

                let subscribe = {
                    request: "join",
                    room: _this._hashCode(_this._roomId),
                    ptype: "subscriber",
                    streams: subscription,
                    use_msid: false,
                    private_id: _this._mypvtid,
                    pin: _this._roomId
                };
                subscriberPlugin.send({ message: subscribe });
            },
            error: function(error) {
                console.log("subscriber  -- Error attaching plugin...", error)
                // onError("subscriber -- Error attaching plugin...", error)
            },
            iceState: function(state) {
                Janus.log("subscriber ICE state (feed #" + subscriberPlugin.rfid + ") changed to " + state);
                // status.value = state
            },
            webrtcState: function(on) {
                console.log("subscriber Janus says this WebRTC PeerConnection (feed #" + subscriberPlugin.rfid + ") is " + (on ? "up" : "down") + " now");
            },
            slowLink: function(uplink, lost, mid) {
                console.log("subscriber Janus reports problems " + (uplink ? "sending" : "receiving") +
                    " packets on mid " + mid + " (" + lost + " lost packets)");
            },
            onmessage: function(msg, jsep) {
                console.log(" ::: Got a message (subscriber) :::", msg)
                let event = msg["videoroom"]
                console.log("Event: " + event)
                if(msg["error"]) {
                    console.log(msg["error"])
                } else if(event) {
                    if(event === "attached") {
                        // Subscriber created and attached
                        console.log("Successfully attached to feed in room " + msg["room"]);
                    } else if(event === "event") {
                        // if (msg.error_code !== undefined && msg.error_code === 428) {
                        //   status.value = "Stream not started"
                        // }
                        // Check if we got a simulcast-related event from this publisher
                        let substream = msg["substream"];
                        let temporal = msg["temporal"];
                        if((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                            if(!subscriberPlugin.simulcastStarted) {
                                subscriberPlugin.simulcastStarted = true;
                            }
                        }
                        // Or maybe SVC?
                        let spatial = msg["spatial_layer"];
                        temporal = msg["temporal_layer"];
                        if((spatial !== null && spatial !== undefined) || (temporal !== null && temporal !== undefined)) {
                            if(!subscriberPlugin.svcStarted) {
                                subscriberPlugin.svcStarted = true;
                            }
                        }
                    } else {
                        // What has just happened?
                    }
                }

                if(jsep) {
                    console.log("Handling SDP as well...", jsep);
                    let stereo = (jsep.sdp.indexOf("stereo=1") !== -1);
                    // Answer and attach
                    subscriberPlugin.createAnswer(
                        {
                            jsep: jsep,
                            // We only specify data channels here, as this way in
                            // case they were offered we'll enable them. Since we
                            // don't mention audio or video tracks, we autoaccept them
                            // as recvonly (since we won't capture anything ourselves)
                            tracks: [
                                { type: 'data' }
                            ],
                            customizeSdp: function(jsep) {
                                if(stereo && jsep.sdp.indexOf("stereo=1") == -1) {
                                    // Make sure that our offer contains stereo too
                                    jsep.sdp = jsep.sdp.replace("useinbandfec=1", "useinbandfec=1;stereo=1");
                                }
                            },
                            success: function(jsep) {
                                console.log("Got SDP!", jsep);
                                let body = { request: "start", room: _this._hashCode(_this._roomId) };
                                subscriberPlugin.send({ message: body, jsep: jsep });
                            },
                            error: function(error) {
                                console.log("WebRTC error: ", error);
                            }
                        });
                }
            },
            // eslint-disable-next-line no-unused-vars
            onlocaltrack: function(track, on) {
                // The subscriber stream is recvonly, we don't expect anything here
                console.log("subscriber onlocaltrack", track, on)
            },
            onremotetrack: (track, mid, on, metadata) => {

                if (metadata && metadata.reason === 'unmute') {
                    return
                }

                console.log("[newRemoteFeed:onremotetrack]", track, mid, on, metadata)
                console.log(
                    "Remote feed #" + subscriberPlugin.rfid +
                    ", remote track (mid=" + mid + ") " +
                    (on ? "added" : "removed") +
                    (metadata? " (" + metadata.reason + ") ": "") + ":", track
                );

                this.onRemoteStream(track , id , display , on , metadata)
            },
            oncleanup: function() {
                console.log(" ::: Got a cleanup notification (remote feed " + id + ") :::");
                // onCleanup()
            }
        })
    }

    // technical

    _hashCode(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return Math.abs(hash);
    }

}

export default WsJanusStreamer