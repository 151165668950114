<template>
    <div>
      <v-icon color="#fff" class="mb-2" size="24">{{ icon }}</v-icon>
      <h5 class="mb-2">{{ title }}</h5>
      <p style="font-size: 12px; max-width: 250px">{{ subtitle }}</p>
      <slot name="action"></slot>
    </div>
</template>

<script>
export default {
  name: "wsSidebarWindowNoContent.vue",
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">

</style>
