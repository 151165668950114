<template>
  <div   @click="dropdown = true" :style="!SM ? 'width: 250px' : null" :class="SM ? 'mr-n1 mt-2' : null">

    <v-menu  :value="dropdown"
             @input="dropdown = $event"
             :close-on-content-click="false"
             :close-on-click="!serverSearch"
             offset-y
             z-index="9999999999999999999999999"
    >
      <template #activator="{attrs}">
        <v-sheet v-bind="attrs">
          <ws-text-field
              v-if="!SM"
              v-model="filters.search"
              min-width="200px"
              @input="handleSearch"
              :placeholder="$t('SearchAndFilters')"
              @click="dropdown = true"
              @focus="focused = true;"
              @blur="focused = false"
              clearable
              icon="mdi-magnify"
          />
          <v-btn
              v-else
              class="mb-2 mr-2" :color="wsACCENT" icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-sheet>

      </template>
      <v-sheet :min-width="!SM ? 400 : null"  class="pa-6" >

        <div class="d-flex justify-space-between align-center mb-4">
          <h4 >
            <v-icon :color="wsACCENT">mdi-tune-vertical</v-icon>
            {{ $t('Filters') }}
          </h4>
          <v-btn @click="dropdown = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>



        <v-simple-table :style="`border: 0.5px solid ${wsBACKGROUND}`" >
          <tbody>

          <tr v-if="SM"     style="background-color: transparent">
            <td :style="`border-color:  ${wsBACKGROUND}`">
              <v-sheet color="transparent" class="d-flex align-center justify-space-between">
                <h5   :style="`color : ${filters.search ? '' : wsDARKLIGHT }`">
                  {{  $t('Search') }} :
                </h5>

                <ws-text-field
                    class="mb-1"
                    v-model="filters.search"
                    @input="handleSearch"
                    solo
                    flat
                />

                <div style="width: 24px" class="d-flex align-center">
                  <v-btn
                      v-if="filters.search"
                      @click.stop="filters.search = null"
                      icon small>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </div>

              </v-sheet>

            </td>
          </tr>
          <tr v-for="item in items"
              style="background-color: transparent"
              :key="item.value"
          >
            <td :style="`border-color:  ${wsBACKGROUND}`">

              <ft-select
                  v-if="item.type === 'select' || item.type === 'select_multiple'"
                  v-model="filters[item.value]"
                  @input="handleSearch"
                  :multiple=" item.type === 'select_multiple'"
                  :searchable="item.items.length > 7"
                  :items="item.items">
                <template #default="{text}">
                  <v-sheet color="transparent" class="d-flex align-center justify-space-between">
                    <h5 v-if="item.type !== 'select_multiple'" :style="`color : ${filters[item.value] !== null && filters[item.value] !== undefined  ? '' : wsDARKLIGHT }`">
                      {{  item.text  }} : {{ text }}
                    </h5>
                    <h5 v-else :style="`color : ${ Array.isArray(filters[item.value])  && filters[item.value].length > 0  ? '' : wsDARKLIGHT }`">
                      {{  item.text  }} : {{ text }}
                    </h5>

                    <div class="d-flex align-center">
                      <v-btn
                          v-if="filters[item.value] !== null && filters[item.value] !== undefined"
                          @click.stop="filters[item.value] = null"
                          icon small>
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>

                      <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
                    </div>

                  </v-sheet>
                </template>

              </ft-select>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <div v-if="serverSearch" class="d-flex justify-end mt-5">
          <ws-button :disabled="Object.keys(buffer).length === 0" @click="resetSearch" text label="ResetSearch" :color="wsACCENT" class="mr-2" />
          <ws-button :disabled="LOADING" @click="search" label="Search" />
        </div>

      </v-sheet>
    </v-menu>


  </div>

</template>

<script>
export default {
  name: "wsSearchFilters",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    items : {
      type : Array,
      default() { return [] }
    },
    serverSearch : {
      type : Boolean,
      default : false
    },
    serverSearchAjax :  {
      type : Function,
    }
  },
  watch : {
    value() {
      if ( this.value !== this.filters ) {
        this.filters = JSON.parse(JSON.stringify(this.value))
      }
    }
  },
  data() {
    return {
      focused : false,
      dropdown : false,
      filters : {},
      buffer : {}
    }
  },
  computed : {
    valuesAreEqual() {
      if ( !this.serverSearch ) {
        return false
      }
      if ( this.filters === {} && this.buffer === {}) {
        return true
      }
      return this.EQ(this.filters , this.buffer)
    }
  },
  methods : {
    search() {
      this.$emit('input',this.filters)
      this.buffer = this.COPY(this.filters)
      this.dropdown = false
    },
    handleSearch() {
      if ( this.serverSearch ) {
        return
      }
      this.$emit('input',this.COPY(this.filters))
    },
    resetSearch() {
      this.filters = {}
      this.$emit('input',this.COPY(this.filters))
      this.dropdown = false
    }
  },
  mounted() {
    if ( this.value !== this.filters ) {
      this.filters = JSON.parse(JSON.stringify(this.value))
    }
    if ( Object.keys(this.filters).length === 0 ) {
      this.items.forEach(item => {
        this.filters[item.value] = item.type === 'select_multiple' ? [] : null
      })
      this.filters = JSON.parse(JSON.stringify(this.filters))
    }
  }
}
</script>

<style scoped>

</style>