import adapter from 'webrtc-adapter'
import Janus from 'janus-gateway'


class WsJanusClient {

    constructor(server) {
        this._JANUS_URL = server || ['ws://localhost:8188', 'http://localhost:8088/janus']
        this._janus = null
        this._plugin = null
        this._opaqueId = Janus.randomString(12)

        this._userId = null
        this._roomId = null

        this._myid = null
        this._mypvtid = null

        this._videoCallback = null
        this._audioCallback = null
        this._fps = 36

        this._sessionId = null
        this._handleId = null


        Janus.init({
            debug: true,
            dependencies: Janus.useDefaultDependencies({ adapter: adapter }),
            callback: () => {
                console.log("Janus.init success")
            }
        })

    }

    // Janus Main functions

    async joinStream({token, roomId, userId, videoCallback, audioCallback , stopCallback}) {
        this._userId = userId
        this._roomId = roomId
        this._videoCallback = videoCallback
        this._audioCallback = audioCallback
        this._stopCallback = stopCallback
        console.log(token)
        try {

            this._janus = new Janus({
                server: this._JANUS_URL,
                token: token,
                success: () => {
                    this._sessionId = this._janus.getSessionId()

                    this.attachStreamingPlugin()
                    console.log('Janus Success')
                },
                error: (error) => {
                    console.error('Failed to connect to Janus server', error)
                    // onError('Failed to connect to janus server', error)
                    this._stopCallback( 'error' ,  error)
                },
                destroyed: () => {
                    this._stopCallback('destroyed')
                    console.log("destroyed")
                }
            })
        } catch (error) {
            // onError('Error accessing camera:', error)
            console.log('Error accessing camera:', error)
        }
    }

    async startClientStream(video, audio) {
        try {

            let mediaConfig = []

            if (video) {
                mediaConfig.push({
                    type: 'video',
                    capture: video.getVideoTracks()[0],
                    recv: false,
                    add: true
                })
            }
            if (audio) {
                mediaConfig.push({
                    type: 'video',
                    capture: audio.getAudioTracks()[0],
                    recv: false,
                    add: true
                })
            }
            if (!mediaConfig.length) {
                console.log('no media config')
                return
            }

            console.log("Media Configuration for Publisher set! ->");
            console.log(mediaConfig);


            this._plugin.createOffer({
                tracks: mediaConfig,
                success: (sdpAnswer) => {
                    // SDP Offer answered, publish our stream
                    console.log("Offer answered! Start publishing...")
                    let publish = {
                        request: "configure",
                        audio: !!audio,
                        video: !!video,
                        data: true
                    };
                    this._plugin.send({ message: publish, jsep: sdpAnswer })
                },
                error: (error) => {
                    console.log("createOffer error", error)
                }
            })
        } catch (error) {
            console.log('Error accessing camera:', error)
        }
    }

    // Janus Technical functions

    attachStreamingPlugin() {

        this._janus.attach({
            plugin: "janus.plugin.videoroom",
            opaqueId: this._opaqueId,
            success: (pluginHandle) => {
                console.log("Publisher plugin attached!");
                console.log(pluginHandle);
                this._plugin = pluginHandle
                this._handleId = pluginHandle.id
                console.log('WHAT I NEED HERE IS: ' , this._sessionId , this._handleId );

                let request = {
                    request: "join",
                    ptype: "publisher",
                    token : 'PEDERAST',
                    display: JSON.stringify({
                        name: "broadcaster",
                        user_id: this._userId,
                        session : this._sessionId,
                        handle : this._handleId
                    }),
                    room: this._hashCode(this._roomId),
                    id: this._hashCode(this._userId) + Date.now(),
                    pin: this._roomId
                };

                this._plugin.send({ message: request });
            },
            onmessage: async (message, jsep) => {
                console.log("onmessage", message, jsep)

                if (message.videoroom === "joined" && message.publishers ) {
                    Janus.log("Successfully joined room with publishers " + message["room"] + " with ID " + message["id"]);

                    let list = message.publishers

                    for(let publisher of list) {
                        if(publisher.dummy)  continue;

                        let id = publisher.id;
                        let streams = publisher.streams;
                        let display = publisher.display;

                        for(let item of streams) {
                            let stream = item;
                            stream.id = id;
                            stream.display = display;
                        }
                        console.log("CLIENTOO  >> [" + id + "] " + display + ":", streams);
                        this.newRemoteFeed(id, display, streams);
                    }

                }

                if (message.videoroom === "destroyed") {
                    // Room has been destroyed, time to leave...
                    console.log("Room destroyed! Time to leave...")
                }

                if (message.unpublished) {
                    // We've gotten unpublished (disconnected, maybe?), leaving...
                    if (message.unpublished === "ok") {
                        console.log("We've gotten disconnected, hanging up...")
                        this._plugin.hangup();
                    }
                }

                if (jsep) {
                    console.log("Handling remote JSEP SDP")
                    console.log(jsep)
                    this._plugin.handleRemoteJsep({ jsep: jsep })
                }

                if (message.videoroom === "joined") {
                    this._myid = message["id"];
                    this._mypvtid = message["private_id"];
                    Janus.log("Successfully joined room " + message["room"] + " with ID " + message["id"]);

                    if (message["publishers"]) {
                        let list = message["publishers"];
                        Janus.debug("Got a list of available publishers/feeds:", list);
                        for(let f in list) {
                            if(list[f]["dummy"])
                                continue;
                            let id = list[f]["id"];
                            let streams = list[f]["streams"];
                            let display = list[f]["display"];
                            for(let i in streams) {
                                let stream = streams[i];
                                stream["id"] = id;
                                stream["display"] = display;
                            }
                            console.log("  >> [" + id + "] " + display + ":", streams);
                            this.newRemoteFeed(id, display, streams);
                        }
                    }
                }

                if (message.videoroom === "event") {
                    console.log("videoroom === event")

                    if (message["publishers"]) {
                        let list = message["publishers"];
                        console.log("Got a list of available publishers/feeds:", list);
                        for(let f in list) {
                            if(list[f]["dummy"])
                                continue;

                            let id = list[f]["id"];
                            let streams = list[f]["streams"];
                            let display = list[f]["display"];

                            for(let i in streams) {
                                let stream = streams[i];

                                stream["id"] = id;
                                stream["display"] = display;
                            }
                            console.log("  >> [" + id + "] " + display + ":", streams);
                            this.newRemoteFeed(id, display, streams);
                        }
                    }
                }
            },
            onremotetrack: (s, mid, on, metadata) => {
                console.log("I HAVE ONREMOTETRACK", s, mid, on, metadata)
            },
            error: (err) => {
                console.log("Publish: Janus VideoRoom Plugin Error!", true);
                console.log(err, true);
            },
            ondetached: () => {
                console.log("ondetached plugin")
            }
        })
    }

    newRemoteFeed(id, display, streams) {
        // A new feed has been published, create a new plugin handle and attach to it as a subscriber
        let subscriberPlugin = null;
        let _this = this

        this._janus.attach({
            plugin: "janus.plugin.videoroom",
            opaqueId: this._opaqueId,
            success: function(pluginHandle) {
                subscriberPlugin = pluginHandle;
                subscriberPlugin.remoteTracks = {};
                subscriberPlugin.remoteVideos = 0;
                subscriberPlugin.simulcastStarted = false;
                subscriberPlugin.svcStarted = false;
                Janus.log("Plugin attached! (" + subscriberPlugin.getPlugin() + ", id=" + subscriberPlugin.getId() + ")");
                Janus.log("  -- This is a subscriber");
                // Prepare the streams to subscribe to, as an array: we have the list of
                // streams the feed is publishing, so we can choose what to pick or skip
                let subscription = [];
                for(let i in streams) {
                    let stream = streams[i];
                    // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                    if(stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                        ((stream.codec === "vp9" && !Janus.safariVp9) || (stream.codec === "vp8" && !Janus.safariVp8))) {
                        console.log("Publisher is using " + stream.codec.toUpperCase +
                            ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
                        continue;
                    }
                    subscription.push({
                        feed: stream.id,  // This is mandatory
                        mid: stream.mid   // This is optional (all streams, if missing)
                    });
                    // FIXME Right now, this is always the same feed: in the future, it won't
                    subscriberPlugin.rfid = stream.id;
                    subscriberPlugin.rfdisplay = stream.display;
                }
                // We wait for the plugin to send us an offer
                let subscribe = {
                    pin: _this._roomId,
                    private_id: _this._mypvtid,
                    ptype: "subscriber",
                    request: "join",
                    room: _this._hashCode(_this._roomId),
                    streams: subscription,
                    use_msid: false
                }
                console.log("newRemoteFeed subscribe", subscribe)
                subscriberPlugin.send({ message: subscribe });
            },
            error: function(error) {
                console.log("subscriber  -- Error attaching plugin...", error)
                // onError("subscriber -- Error attaching plugin...", error)
            },
            iceState:  (state) => {
                if (state === 'failed' || state === 'disconnected') {
                    this._stopCallback('connection_lost',state)
                    this.destroySession()
                }
                Janus.log("subscriber ICE state (feed #" + subscriberPlugin.rfid + ") changed to " + state);
                // status.value = state
            },
            webrtcState: function(on) {
                console.log("subscriber Janus says this WebRTC PeerConnection (feed #" + subscriberPlugin.rfid + ") is " + (on ? "up" : "down") + " now");
            },
            slowLink: function(uplink, lost, mid) {
                console.log("subscriber Janus reports problems " + (uplink ? "sending" : "receiving") +
                    " packets on mid " + mid + " (" + lost + " lost packets)");
            },
            onmessage: function(msg, jsep) {
                console.log(" ::: Got a message (subscriber) :::", msg)
                let event = msg["videoroom"]
                console.log("Event: " + event)
                if(msg["error"]) {
                    console.log(msg["error"])
                } else if(event) {
                    if(event === "attached") {
                        // Subscriber created and attached
                        console.log("Successfully attached to feed in room " + msg["room"]);
                    } else if(event === "event") {
                        if (msg.error_code !== undefined && msg.error_code === 428) {
                            console.log("Stream not started")
                            // status.value = "Stream not started"
                        }
                        // Check if we got a simulcast-related event from this publisher
                        let substream = msg["substream"];
                        let temporal = msg["temporal"];
                        if((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                            if(!subscriberPlugin.simulcastStarted) {
                                subscriberPlugin.simulcastStarted = true;
                            }
                        }
                        // Or maybe SVC?
                        let spatial = msg["spatial_layer"];
                        temporal = msg["temporal_layer"];
                        if((spatial !== null && spatial !== undefined) || (temporal !== null && temporal !== undefined)) {
                            if(!subscriberPlugin.svcStarted) {
                                subscriberPlugin.svcStarted = true;
                            }
                        }
                    } else {
                        // What has just happened?
                    }
                }

                if(jsep) {
                    console.log("Handling SDP as well...", jsep);
                    let stereo = (jsep.sdp.indexOf("stereo=1") !== -1);
                    // Answer and attach
                    subscriberPlugin.createAnswer(
                        {
                            jsep: jsep,
                            // We only specify data channels here, as this way in
                            // case they were offered we'll enable them. Since we
                            // don't mention audio or video tracks, we autoaccept them
                            // as recvonly (since we won't capture anything ourselves)
                            tracks: [
                                { type: 'data' }
                            ],
                            customizeSdp: function(jsep) {
                                if(stereo && jsep.sdp.indexOf("stereo=1") == -1) {
                                    // Make sure that our offer contains stereo too
                                    jsep.sdp = jsep.sdp.replace("useinbandfec=1", "useinbandfec=1;stereo=1");
                                }
                            },
                            success: function(jsep) {
                                console.log("Got SDP!", jsep);
                                let body = { request: "start", room: _this._hashCode(_this._roomId) };
                                subscriberPlugin.send({ message: body, jsep: jsep });
                            },
                            error: function(error) {
                                console.log("WebRTC error: ", error);
                            }
                        });
                }
            },
            // eslint-disable-next-line no-unused-vars
            onlocaltrack: function(track, on) {
                // The subscriber stream is recvonly, we don't expect anything here
                console.log("subscriber onlocaltrack", track, on)
            },
            onremotetrack: function(track, mid, on, metadata) {
                console.log(
                    "Remote feed #" + subscriberPlugin.rfid +
                    ", remote track (mid=" + mid + ") " +
                    (on ? "added" : "removed") +
                    (metadata? " (" + metadata.reason + ") ": "") + ":", track
                );
                // if(!on) {
                //     if(track.kind === "video") {
                //         delete streamVideos.value[track["id"]]
                //
                //         isViewing.value = Object.keys(streamVideos.value).length > 0
                //     }
                //
                //     return
                // }

                _this.onRemoteStream(track, on , id, display)
            },
            oncleanup: function() {
                console.log(" ::: Got a cleanup notification (remote feed " + id + ") :::");
                // onCleanup()
            }
        })
    }

    destroySession() {
        if (!this._janus) {
            return
        }
        this._janus.destroy({
            success: () => {
                console.log('Disconnected from Janus server');
                this._janus = null; // Clear the Janus instance
            },
            error: (error) => {
                console.error('Error during Janus disconnection', error);
            }
        });
    }

    onRemoteStream(track, on, id, display) {
        console.log("onRemoteStream", track)

        if (track.kind === "audio") {
            // New audio track: create a stream out of it, and use a hidden <audio> element
            let audioStream = new MediaStream([track]);
            console.log("Created remote audio stream:", audioStream);
            // Janus.attachMediaStream(audio.value, stream);
            // audio.value[track["id"]] = audioStream
            this._audioCallback(audioStream, id, on)
        } else {
            if (display !== 'broadcaster') {
                console.log('New stream is not broadcaster, ignore')
                return
            }
            // New video track: create a stream out of it
            let videoStream = new MediaStream([track]);
            console.log("Created remote video stream:", videoStream);
            // Janus.attachMediaStream(streamVideos.value[track["id"]], videoStream);
            this._videoCallback(track["id"], videoStream, on)
        }
    }


    // outer functions

    setSources(sources) {
        for (let source of this._sources) {
            if (!source.dom.srcObject) {
                source.dom.srcObject = source.stream
                console.log('Starting new video' , source.stream)
            }
        }
        this._sources = [...sources]
        console.log('Yanus new sources' , sources)
    }

    getSessionId() {
        return this._sessionId
    }

    getHandleId() {
        return this._handleId
    }



    // technical
    _hashCode(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return Math.abs(hash);
    }
}

export default WsJanusClient