<template>
  <!--  Add members from list dialog -->
  <ws-dialog

    v-model="displayDialog"
    fullscreen-align="right"
    :title="usersItems.length ? titleDialog : ''"
    fill-height
    :width="usersItems.length ? '764' : '600'"
    border-radius="0"
    not-centered
    disable-save
    :hide-buttons="!usersItems.length"
    @close="$emit('close')"
  >

    <template #default>
      <!-- no users content -->
      <div v-if="!usersItems.length" class="d-flex flex-column align-center justify-center text-center" style="height: 100%">
        <v-progress-circular v-if="loading" indeterminate :color="wsACCENT"></v-progress-circular>
        <div v-else>
          <v-icon size="64" :color="wsACCENT" class="mb-4">mdi-account-multiple</v-icon>
          <h2  class="mb-4" :style="`color: ${wsDARKER}`">{{ subtitle }}</h2>
          <p v-if="description" class="mb-4">{{ description }}</p>
          <ws-button
            outlined
            :label="createNewUserLabel"
            :color="wsACCENT"
            @click="student? $emit('open-create-user-dialog') : ''"
            :to="manager ? businessDashLink('settings/select') : '' "/>
        </div>
      </div>

      <div v-else>
          <p style="font-weight: 350; margin-bottom: 13px" >{{ tableTitle }}</p>
          <div class="d-flex align-center justify-space-between mb-6" style="max-height: 40px">
            <v-sheet class=" flex-grow-1">
              <ws-search-filters
                :items="[]"
                style="width: 100%"
              />
            </v-sheet>
            <slot name="action" :entityData="entityData" ></slot>
          </div>

          <ws-data-table
            :headers="headers.filter(el => el.value !== 'paid' && el.value !== 'delete')"
            :items="usersItems"
            selectable
            bordered
            no-footer
            :search="filtersData.search || null"
            :selected-rows="entityData.selectedUsers"
            @select="entityData.selectedUsers = $event.items"
          >
            <template #item.name="{item}">
              <h5>{{ item.lastname }} {{ item.name }}</h5>
            </template>
            <template #item.phone>
              <h5>+32323</h5>
            </template>

          </ws-data-table>
        </div>

    </template>

    <template #buttons v-if="usersItems.length">
      <div class="d-flex align-center justify-space-between" :style="`width: ${usersItems.length === 0 ? '552px' : '716px'}`">
        <ws-button
          :label="createNewUserLabel"
          outlined
          :color="wsACCENT"
          @click="student ? $emit('open-create-user-dialog') : ''"
          :to="manager ? businessDashLink('settings/select') : '' "
        />
        <div>
          <ws-button outlined :label="$t(`Cancel`)" class="mr-2" @click="$emit('close')"/>
          <ws-button :disabled="!isValidData" :label="$t(`Save`)" @click="addToTable"/>
        </div>
      </div>
    </template>

  </ws-dialog>
</template>

<script>
import wsSearchFilters from "@/components/pages/dashboard/UI/wsSearchFilters.vue";
import {mapActions} from "vuex";

export default {
  name: "wsUserSelectDialog.vue",
  components: {wsSearchFilters},
  props: {
    createNewUserLabel: {
      type: String,
      default: ''
    },
    titleDialog: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    tableTitle: {
      type: String,
      default: ''
    },
    displayDialog: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    },
    student: {
      type: Boolean,
      default: false
    },
    manager: {
      type: Boolean,
      default: false
    },
    webinar: {
      type: Object,
      default: () => {}
    }

  },
  data() {
    return {
      loading: true,
      limit: 100,
      usersItems: [],
      entityData: {
        paid: false,
        selectedUsers: []
      },
      filtersData: {},
    }
  },
  computed: {
    isValidData() {
      return this.entityData.selectedUsers.length
    }
  },
  methods:{
    ...mapActions('webinars', ['GET_ALL_USERS', 'ADD_PARTICIPANT', 'GET_ALL_MANAGERS_FOR_WEBINAR']),
    addToTable() {
      const checkedUsers = this.usersItems.filter(el => this.entityData.selectedUsers.includes(el.uuid))
      this.$emit('save' , this.entityData, checkedUsers);

      const filteredUsers = this.usersItems.filter(el => !this.entityData.selectedUsers.includes(el.uuid))
      this.usersItems = filteredUsers
      this.usersItems = this.COPY(this.usersItems)

    },

    async getAllManagers() {
      const result = await this.GET_ALL_MANAGERS_FOR_WEBINAR();
      this.loading = false

      if (!result) {
        this.ERROR();
        return
      }
      this.usersItems = result
    },

    async getAllUsers() {
      const result = await this.GET_ALL_USERS({ uuid: this.webinar.uuid, limit: this.limit });
      this.loading = false

      if (!result) {
        this.ERROR();
        return
      }

      this.usersItems = result.items
    },

  },
  async mounted() {
    if (this.student) {
      await this.getAllUsers()
    }

    if (this.manager) {
      await this.getAllManagers()
    }

  }
}
</script>

<style scoped>

</style>
