<template>
  <v-sheet style="height: 100%; background-color: transparent" class="d-flex flex-column justify-space-between">
    <ws-sidebar-window-header
      :title="$t('webinar.main.preview.surveys')"
      @close="$emit('close')"
      :checked-value="checkedValue"
      :is-full-survey="isFullSurvey"
      @back="backToSurveysList()"
    />

    <v-divider :color="wsACCENT"/>
    <div :class="`rounded d-flex flex-column ${webinarSurveys.length !== 0 ? 'justify-start' : 'justify-space-between'}`" style="height: calc(100% - 60px)">

      <!-- NO SURVEY CONTENT -->
      <div v-if="webinarSurveys.length === 0 && !isNewSurvey" class="d-flex align-center justify-center px-7 text-center" style="color: #fff; height: 100%">
        <ws-sidebar-window-no-content
          icon="mdi-chart-box-outline"
          :title="$t('webinar.main.preview.no_surveys')"
          :subtitle="$t('webinar.main.preview.no_surveys_text')"
        >
          <template #action>
            <ws-button :label="$t('webinar.main.preview.create')" left-icon="mdi-plus" @click="createSurvey"/>
          </template>
        </ws-sidebar-window-no-content>

      </div>

      <!--   Create new survey (form) -->
      <div v-if="isNewSurvey" style="color: #fff" class=" pa-4 scrollable">
        <ws-text-field
          v-model="entityDataSurvey.text"
          :label="$t('webinar.main.preview.surveys')"
          :placeholder="$t('webinar.main.preview.enter_surveys')"
          area
          class="mb-4 white-input-text"
          required
          label-color="#fff"
          color="#fff"
          :style="`border: 1px solid ${wsACCENT}`"
        />

        <ws-text-field
          v-for="(item, index) in surveyAnswersObject.answers"
          :key="index"
          v-model="item.answer"
          :label="`${$t('webinar.main.preview.answer')} ${item.id <= 2 ? item.id + '*' : item.id }`"
          :placeholder="$t('webinar.main.preview.enter_answer')"
          area
          class="mb-4 white-input-text"
          required
          label-color="#fff"
          color="#fff"
          :style="`border: 1px solid ${wsACCENT}`"
        />

        <ws-tooltip top :text="isBlockAddAnswer ? $t('webinar.main.preview.answers_limit') : ''">
          <ws-button
            outlined
            block
            :label="$t('webinar.main.preview.add_answer')"
            left-icon="mdi-plus"
            color="#fff"
            icon-color="#fff"
            :style="`border: 1px solid ${wsACCENT}`"
            :disabled="isBlockAddAnswer"
            @click="addAnswer"
          />
        </ws-tooltip>

      </div>

      <!--   Created Surveys list -->
      <div v-if="webinarSurveys.length !== 0 && !isNewSurvey && !isFullSurvey" style="color: #fff" class="pa-4 pt-6 scrollable">
        <v-hover
          v-slot="{ hover : surveyHover }"
          v-for="survey in webinarSurveys"
          :key="survey.answers.id + survey.question.text"
        >
          <div
            class="pa-2 mb-2 rounded"
            :style="`background-color: ${ surveyHover ? '#9AB2C4' : '#3B5365'}; border: 1px solid ${wsACCENT}; cursor: pointer; max-width: 273px; position: relative`"
          >
            <div class="d-flex align-start justify-space-between mb-2" @click="showFullSurvey(survey)">
              <h5 class="mr-1" >{{ survey.question.text }}</h5>
              <ws-tooltip :text="$t('webinar.main.preview.delete_survey')" style="position: absolute; top: 8px; right: 8px; z-index: 999999">
                <v-icon  :color="wsACCENT" @click.stop="showCancelConfirmDialog(survey)">mdi-delete-outline</v-icon>
              </ws-tooltip>
            </div>

            <span :style="`color: ${wsBACKGROUND}; font-size: 12px`">{{ $t('webinar.main.preview.votes')}}: 245</span>

          </div>
        </v-hover>
      </div>

      <!--    Full survey info    -->
      <div v-if="isFullSurvey" class="pa-4 scrollable" style="color: #fff">
        <h5 class="mb-6">{{ selectedSurvey.question.text }}</h5>

        <div v-for="item in selectedSurvey.answers" :key="item.id" class="mb-6" :style="`color: ${wsBACKGROUND}`">
          <div class="d-flex align-center justify-space-between">
            <span class="mb-2 d-inline-block">{{ $t('webinar.main.preview.answer')}} {{ item.id }}</span>
            <h5>100 (100%)</h5>
          </div>
          <p class="pa-2 rounded" :style="`border: 1px solid ${wsACCENT}; background-color: #3B5365`">{{ item.answer}}</p>

        </div>

      </div>
      <v-divider v-if="isNewSurvey" :color="wsACCENT"/>

      <!-- SURVEYS FOOTER ACTIONS -->
      <ws-sidebar-window-footer
        :hide-buttons="false"
        @cancel="showCancelConfirmDialog"
        @publish="publishSurvey"
        @create="createSurvey"
        :is-valid="isValidSurvey"
        :is-create-new="isNewSurvey"
        :no-items="webinarSurveys.length !== 0"
        :button-label="$t('webinar.main.preview.create_new_survey')"
      >
        <template #actions>
          <div class="d-flex align-center justify-space-between mb-5">
            <span style="font-size: 12px" >{{ $t('webinar.main.preview.show_survey_results') }}</span>
            <ws-switch width="48px" style="height: 24px" v-model="entityDataSurvey.isPublic" />
            <!--                           style="height: 24px"-->
          </div>
        </template>
      </ws-sidebar-window-footer>
    </div>

    <!--   Survey confirm delete dialog -->
    <ws-dialog
      v-model="displayCancelConfirm"
      :title="$t('webinar.main.preview.survey_delete')"
      :cancel-text="$t('Cancel')"
      :save-text="$t('Delete')"
      @save="confirmDelete"
    >
      <p>{{ $t('webinar.main.preview.confirm_delete_text') }}</p>
    </ws-dialog>

  </v-sheet>

</template>

<script>
import WsSidebarWindowNoContent
  from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowNoContent.vue";
import WsSidebarWindowFooter from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowFooter.vue";
import WsSidebarWindowHeader from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowHeader.vue";

export default {
  name: "wsSliderSurveys.vue",
  components: {WsSidebarWindowHeader, WsSidebarWindowFooter, WsSidebarWindowNoContent},
  props: {
    checkedValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      webinarSurveys: [],
      isNewSurvey: false,
      isFullSurvey: false,
      displayCancelConfirm: false,
      isBlockAddAnswer: false,
      surveyAnswersObject:
        {
          id: 0,
          question: {},
          answers: [
            { id: 1, answer: '', protected: true },
            { id: 2, answer: '', protected: true },
          ]
        },
      entityDataSurvey: {
        isPublic: false
      },
      selectedSurvey: {},
    }
  },
  computed: {
    isValidSurvey() {
      const filledAnswers = this.surveyAnswersObject.answers.filter(el => el.answer !== '' && el.answer.trim() !== '');

      if (filledAnswers.length < 2) {
        return false
      }

      if (!this.entityDataSurvey.text) {
        return false
      }
      return true
    },
  },
  methods: {
    createSurvey() {
      this.isFullSurvey = false;
      this.surveyAnswersObject = {
        question: {},
        answers: [
          {id: 1, answer: '', protected: true},
          {id: 2, answer: '', protected: true},
        ]
      }
      this.entityDataSurvey = {
        isPublic: false
      };

      this.isNewSurvey = true;
    },
    showFullSurvey(survey) {
      this.isFullSurvey = true
      this.selectedSurvey = survey
    },
    publishSurvey() {
      this.surveyAnswersObject.question = this.entityDataSurvey
      const survey = this.webinarSurveys.at(-1)

      if (!survey || survey === 'undefined') {
        this.surveyAnswersObject.id = 1
      } else {
        this.surveyAnswersObject.id = ++survey.id
      }

      this.webinarSurveys.push(this.surveyAnswersObject);
      this.webinarSurveys = this.COPY(this.webinarSurveys)
      this.isNewSurvey = false
    },
    showCancelConfirmDialog(survey) {
      this.displayCancelConfirm = true;
      this.selectedSurvey = survey

    },
    addAnswer() {
      this.surveyAnswersObject.answers.push( { answer: '', id: this.surveyAnswersObject.answers.length + 1, protected: false })

      this.surveyAnswersObject.answers.length !== 5
        ? this.isBlockAddAnswer = false
        : this.isBlockAddAnswer = true
    },
    confirmDelete() {
      const index = this.webinarSurveys.findIndex(el => el.id === this.selectedSurvey.id)

      if (index === -1) {
        this.ERROR();
        return
      }

      this.webinarSurveys.splice(index, 1)
      this.webinarSurveys = this.COPY(this.webinarSurveys)
      this.isNewSurvey = false;
      this.displayCancelConfirm = false;
    },
    backToSurveysList() {
      this.isFullSurvey = false;
    },
  },
}
</script>

<style scoped lang="scss">
.white-input-text ::v-deep textarea::placeholder {
  color: #9AB2C4!important;
}
.white-input-text ::v-deep input, .white-input-text ::v-deep textarea {
  color: #fff!important;
}
.scrollable {
  overflow-y: scroll;
  height: 100%;

  &::v-deep {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #4E6678;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #4E6678;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
</style>
