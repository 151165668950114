<template>
  <div v-if="items.length > 0" style="width: 100%">
    <v-hover #default="{hover}">
      <div :style="`color: ${wsWHITE}`" class="d-flex align-center justify-start py-2 px-5 ">
        <v-icon
            :color="wsWHITE"
            class="mr-3 transition-ease-in-out rounded "
            style="cursor: pointer;"
            :style="`background-color: ${ hover ? wsACCENT : 'transparent'}`"
            @click="expand = !expand"
            v-ripple
        >
          {{ `mdi-menu-${!expand ? 'right' : 'down'} `}}
        </v-icon>
        <h5>
          {{ title }}
          <span :style="`color: ${wsACCENT}; font-weight: 400; font-size: 12`">({{ items.length }})</span>
        </h5>
      </div>
    </v-hover>
    <v-expand-transition>

      <div v-if="expand">

        <webinar-participant
            v-for="(item ) in items" :key="item.uuid"
            :item="item"
            :type="type"
        />


      </div>
    </v-expand-transition>

  </div>

</template>

<script>
import webinars from "@modules/webinars/mixins/webinars";
import webinarParticipant
  from "@modules/webinars/components/Webinar/admin/room/sidebars/participants/webinarParticipant.vue";
export default {
  name: "wsDropdownHeader.vue",
  mixins: [webinars],
  components : {
    webinarParticipant
  },
  props: {
    length: {
      type: Number, String,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    type : {
      type: String,
      default: 'students'
    }
  },
  data() {
    return {
      expand: false,
    }
  },
  computed : {
    items() {
      if (!this.type) return []

      return (this[this.type] || []).sort((a, b) => {
        return (b.is_online ? 1 : 0) - (a.is_online ? 1 : 0);
      }).sort((a, b) => {
        return (b.is_requesting_stream ? 1 : 0) - (a.is_requesting_stream ? 1 : 0);
      })

    },
    students() {
      if (!this.PARTICIPANTS) {
        return []
      }
      return this.PARTICIPANTS.filter(el => !el.manager)
    },
    managers() {
      if (!this.PARTICIPANTS) {
        return []
      }
      return this.PARTICIPANTS.filter(el => !!el.manager)
    },
  },

}
</script>

<style scoped>
.v-icon, .v-icon::after {
  content: none !important;
}
</style>
