import { render, staticRenderFns } from "./wsSidebarWindowFooter.vue?vue&type=template&id=0618cacc&scoped=true&"
import script from "./wsSidebarWindowFooter.vue?vue&type=script&lang=js&"
export * from "./wsSidebarWindowFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0618cacc",
  null
  
)

export default component.exports