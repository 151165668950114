function drawMainSource(context,source,canvas) {
    context.drawImage(source.dom, 0, 0, canvas.width, canvas.height)
}
function drawSecondarySource(context,sources,canvas) {

    let sourcesCount = sources.length

    if (sourcesCount > 4) {
        sourcesCount = 4
    }

    for (let index = 0; index < sourcesCount; index++ ) {

        const source = sources[index];

        const padding = 12;
        const width = canvas.width/4 - (padding*1.5)
        const height = canvas.height/4 - (padding*1.5)

        let x = canvas.width - width - padding
        if (index > 0 ) {
            x -= (width + padding)*index
        }
        let y =  canvas.height - height - padding

        context.drawImage(source.dom, x, y, width, height)
    }

}

function drawCanvas(context,sources,canvas) {

    let mainSource = sources.find(el => el.main)
    let secondarySources = sources.filter(el => !el.main)

    if (!mainSource) {
        mainSource = Object.assign({}, sources[0])
        secondarySources.pop()
    }

    if (mainSource) {
        drawMainSource(context,mainSource,canvas)
    }
    if (secondarySources) {
        drawSecondarySource(context,secondarySources,canvas)
    }

}

export default {
    drawCanvas : drawCanvas
}