<template>
  <div style="color: #fff; height: fit-content; position: relative" :class="`${ !hideButtons ? 'pa-4' : 'px-1 py-3'}`" >
    <slot name="input"></slot>

    <div v-if="!hideButtons" >
      <slot v-if="isCreateNew" name="actions"></slot>

      <div v-if="isCreateNew" class="d-flex align-center justify-space-between " style="column-gap: 16px">
        <ws-button outlined :label="$t('Cancel')" :color="wsBACKGROUND" style="flex-grow: 1" @click="$emit('cancel')" />
        <ws-tooltip top :text="$t('webinar.main.preview.fill_necessary_fields')" >
          <ws-button :label="$t('Publish')" style="flex-grow: 1" :disabled="!isValid" @click="$emit('publish')"/>
        </ws-tooltip>
      </div>

      <div v-if="noItems && !isCreateNew">
        <ws-button block :label="buttonLabel" @click="$emit('create')"/>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "wsSidebarWindowFooter.vue",
  props: {
    hideButtons: {
      type: Boolean,
      default: true
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isCreateNew: {
      type: Boolean,
      default: true
    },
    noItems: {
      type: Boolean,
      default: true
    },
    buttonLabel: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
