<template>
  <v-sheet style="height: 100%; background-color: transparent" class="d-flex flex-column justify-space-between">
    <!--   Sidebar participants header -->

    <ws-sidebar-window-header
      :title="$t('webinar.settings.members')"
      @close="$emit('close')"
      :checked-value="checkedValue"
    />


    <v-divider :color="wsACCENT"/>
    <webinar-participants-group
        v-for="type in participantsTypes" :key="type.value"
        :type="type.value"
        :title="type.text"
    />
    <div class="flex-grow-1"></div>

    </v-sheet>


</template>

<script>
import webinarParticipantsGroup
  from "@modules/webinars/components/Webinar/admin/room/sidebars/participants/webinarParticipantsGroup.vue";
import WsSidebarWindowHeader from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowHeader.vue";
import webinars from "@modules/webinars/mixins/webinars";

export default {
  name: "wsSidebarParticipants.vue",
  mixins: [webinars],
  components: {WsSidebarWindowHeader, webinarParticipantsGroup},
  props: {
    checkedValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      participantsTypes : [
        { text : this.$t('webinar.settings.moderators')  , value : 'managers' },
        { text : this.$t('webinar.settings.members'), value : 'students' }
      ],
      showModeratorsList: false,
      showParticipantsList: false,
      selectedChatParticipant: {},
      webinarParticipants: [
        { name: 'User Name', avatar: '', isVideo: false, isMicrophone: false, value: 'user-1'},
        { name: 'User Name 2', avatar: 'mod-avatar.png', isVideo: true, isMicrophone: false, value: 'user-2' },
        { name: 'User Name 3', avatar: '', isVideo: false, isMicrophone: true, value: 'user-3' },
      ],
      webinarModerators: [
        { text: 'Moderator', value: 'Moderator', avatar: 'mod-avatar.png', isVideo: false, isMicrophone: false, isLector: true },
        { text: 'Moderator 2', value: 'Moderator 2', avatar: '', isVideo: true, isMicrophone: true, isLector: false },
      ],
    }
  },
  computed: {
    chatParticipantActionsSelect() {
      return [
        {
          text: this.$t('webinar.main.preview.stop_access'),
          value: 'stop_access',
          action: () => { this.stopAccessToChat() }
        },
        {
          text: this.$t('webinar.main.preview.block_member'),
          value: 'block_member',
          action: () => { this.blockMemberInChat() }
        },
        {
          text: this.$t('webinar.main.preview.delete_member'),
          value: 'delete_member',
          action: () => { this.deleteMemberFromChat() }
        },
      ]
    },
  },
  methods: {
    stopAccessToChat() {
      this.notify('stop access to chat')
      this.selectedChatParticipant = {}
    },
    blockMemberInChat (){
      this.notify('block member in the chat')
      this.selectedChatParticipant = {}
    },
    deleteMemberFromChat() {
      this.notify('del member from chat');
      this.selectedChatParticipant = {}
    },
  }
}
</script>


<style scoped lang="scss">
.scrollable {
  overflow-y: scroll;
  height: 100%;

  &::v-deep {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #4E6678;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #4E6678;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
</style>
