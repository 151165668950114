import { render, staticRenderFns } from "./webinarVideoStudent.vue?vue&type=template&id=6375d228&scoped=true&"
import script from "./webinarVideoStudent.vue?vue&type=script&lang=js&"
export * from "./webinarVideoStudent.vue?vue&type=script&lang=js&"
import style0 from "./webinarVideoStudent.vue?vue&type=style&index=0&id=6375d228&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6375d228",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VImg,VSheet})
