<template>
  <dash-page-new
    class="d-flex flex-md-column"
    no-padding
    no-header
  >

    <template #default>
      <!-- Header -->


      <div class="px-11 pt-3 ">
        <h5 class="pb-1" :style="`color: ${wsATTENTION}`">{{ $t('webinar.details.all_webinars') }}</h5>
        <div class="d-flex align-center justify-space-between "  >

          <h5 style="font-size: 22px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: 100%" class="mr-6">{{webinar.name}}</h5>

          <div class="d-flex align-center justify-space-between flex-shrink-0">
            <div
              class="d-flex align-start mr-2"
              :style="`color: ${wsACCENT}`"
            >

              <v-icon size="18"  :color="`${recordColor}`" class="mr-2">{{ webinar.status === 'draft' ? 'mdi-circle-outline' : 'mdi-record' }}</v-icon>
              <h5>{{ webinarStatus }}</h5>
            </div>

            <v-hover v-slot="{hover : linkHover}">
              <ws-tooltip :text="$t('webinar.details.copy_link_to_webinar')">
                <v-icon
                  :color="wsACCENT"
                  class="pa-2 rounded"
                  :style="`box-shadow: 0px 0px 0px 2px ${linkHover ? wsBACKGROUND : 'transparent'}; opacity: ${linkHover ? '0.9' : '1'}`"
                  @click="copyToClipboard"
                >mdi-link</v-icon>
              </ws-tooltip>
            </v-hover>

            <ft-select
              @expand="selectedItem = item"
              :items="settingsActions"
              left-icons
            >
              <v-sheet class="px-4 py-2">
                <v-icon :color="`${wsACCENT}`" class="mr-2">mdi-cog-outline</v-icon>
                <v-icon :color="`${wsACCENT}`">mdi-menu-down</v-icon>
              </v-sheet>

            </ft-select>

            <ws-button
              :label="$t('webinar.details.to_account')"
              @click="openWebinarWindowDialog"
            />
          </div>
        </div>
      </div>


      <v-sheet class="mt-4 " color="transparent">
        <!-- Statistics -->

        <div
          class="d-flex flex-row justify-space-between "
          :class="[
           { 'px-11' : !SM } ,
           { 'px-4'  : SM }
          ]"
        >
          <v-sheet
            v-for="(item,i) in statisticsSelect" :key="i"
            style="border: 1px solid var(--wsBACKGROUND);"
            class="wsRoundedSemi flex-grow-1 pa-4"
            :class="[{'ml-4' : i !== 0 }]"
          >
            <v-icon :color="wsACCENT">{{ item.icon }}</v-icon>
            <h5 style="font-size: 12px" class="py-1 wsACCENT">{{ item.title }}</h5>
            <h3 style="font-size: 19px" class="wsACCENT">
              {{ item.text }}
              <span class="font-weight-regular" v-if="item.text_regular">({{ item.text_regular }})</span>
            </h3>
          </v-sheet>
        </div>
        <!-- Tab Header -->
        <ws-navigation-header
          :items="navigationSelect"
          v-model="navigation"
          class="mt-4"
          padding="2px"
        />
      </v-sheet>

      <!-- Content -->
      <v-sheet
        :class="[{ 'px-10' : !SM },{ 'px-4'  : SM }]"
        class="flex-grow-1"
      >
        <ws-navigation
          :items="navigationSelect"
          v-model="navigation"
          hide-header
        >
          <template #item.participants>
            <v-sheet class="wsRoundedLight mt-2" style="border : 1px solid var(--wsBACKGROUND);">
              <ws-data-table
                :items="participantsItems"
                :headers="headers"
                no-footer
              >
                <template #item.name="{item}">
                  <h5 :style="`color: ${wsDARKER}`">
                    <v-icon class="mr-2">mdi-account-circle </v-icon>
                    {{ item.name }}
                  </h5>
                </template>
                <template #item.email="{item}">
                  <h5 :style="`color: ${wsDARKER}`">{{ item.email }}</h5>
                </template>
                <template #item.phone="{item}">
                  <h5 :style="`color: ${wsDARKER}`">{{ item.phone }}</h5>
                </template>
                <template #item.actions="{item}">
                  <ws-select
                    :items="memberActionsSelect"
                    v-model="item.actions"
                    solo
                    flat
                    background-color="transparent"
                    hide-details
                    class="table-select"
                    :placeholder="$t('webinar.details.check_action')"
                  />
<!--                  <h5 :style="`color: ${wsDARKER}`" class="text-no-wrap">{{ item.actions ? item.actions.text : $t('webinar.details.check_action') }}</h5>-->
                </template>
                <template #item.actionsTime>
                  <h5 :style="`color: ${wsDARKER}`" class="text-no-wrap">action-time</h5>
<!--                  <h5 :style="`color: ${wsDARKER}`" class="text-no-wrap">{{ MONTH_DAY_TIME(item.actionsTime) }}</h5>-->
                </template>
              </ws-data-table>

              <div v-if="participantsItems.length === 0" class="py-16" style="height: 100%">
                <div class="d-flex justify-center">
                  <v-icon size="80" :color="wsDARKLIGHT">mdi-account-multiple</v-icon>
                </div>
                <h3 class="wsDARKER text-center">{{ $t('webinar.settings.no_webinar_members') }}</h3>
              </div>
            </v-sheet>

          </template>
          <template #item.moderators>
            <v-sheet class="wsRoundedLight mt-2" style="border : 1px solid var(--wsBACKGROUND);">
              <ws-data-table
                :items="webinarModerators"
                :headers="moderatorsHeaders"
                no-footer
                :style="`color: ${wsDARKER}`"
              >
                <template #item.name="{item}">
                  <h5 :style="`color: ${wsDARKER}`">
                    <v-icon class="mr-2">mdi-account-circle </v-icon>
                    {{ item.name }}
                  </h5>
                </template>
                <template #item.email="{item}">
                  <h5 :style="`color: ${wsDARKER}`">{{ item.email }}</h5>
                </template>
                <template #item.phone="{item}">
                  <h5 :style="`color: ${wsDARKER}`">{{ item.phone }}</h5>
                </template>

              </ws-data-table>
              <div v-if="webinarModerators.length === 0" class="py-16" style="height: 100%">
                <div class="d-flex justify-center">
                  <v-icon size="80" :color="wsDARKLIGHT">mdi-account-multiple</v-icon>
                </div>
                <h3 class="wsDARKER text-center">{{ $t('webinar.settings.no_webinar_moderators') }}</h3>
              </div>
            </v-sheet>

          </template>
          <template #item.about>
            <v-sheet class="rounded mx-auto" style="max-width: 619px; overflow: hidden; border: 1px solid var(--wsBACKGROUND)">
              <div class="pa-4">
                <h4>{{ webinar.name }}</h4>
                <p style="font-size: 13px; line-height: 180%" :style="`color: ${wsACCENT}`" class="font-weight-regular mb-0">{{ webinar.description }}</p>
              </div>
              <v-divider :style="`color: ${wsBACKGROUND}`"/>

              <div class="pa-4 text-center">
                <h4 class="mb-2 text-left">{{ $t('webinar.details.webinar_image') }}</h4>
                <img :src="webinar.image ? webinar.image : require(`@/modules/webinars/assets/main.png`)" alt="webinar image" >
              </div>
              <v-divider :style="`color: ${wsBACKGROUND}`"/>

              <div class="pa-4">
                <h4>{{ $t('webinar.details.webinar_start_time') }}</h4>
                <span style="font-size: 13px;" :style="`color: ${wsACCENT}`" class="font-weight-regular">{{ webinar.date_start }}</span>
              </div>
              <v-divider :style="`color: ${wsBACKGROUND}`"/>

              <div class="pa-4">
                <h4>{{ $t('webinar.settings.payment_title') }}</h4>
                <span style="font-size: 13px;" :style="`color: ${wsACCENT}`" class="font-weight-regular">
                  {{ !webinar.paid ? $t('webinar.details.free') : webinar.price }}
                </span>
              </div>
              <v-divider :style="`color: ${wsBACKGROUND}`"/>

              <div class="pa-4">
                <h4>{{ $t('webinar.details.access_title') }}</h4>
                <span style="font-size: 13px;" :style="`color: ${wsACCENT}`" class="font-weight-regular">
                  {{ $t('webinar.details.webinar_public') }}
                </span>
                <a href="#" style="font-size: 13px;" :style="`color: ${wsATTENTION}`" class="font-weight-regular mr-1 text-decoration-none">
                  westudy.ua/webinars/folk
                  <v-icon :color="wsATTENTION">mdi-open-in-new</v-icon>
                </a>

              </div>
              <v-divider :style="`color: ${wsBACKGROUND}`"/>

              <div v-if="webinar.scripts_enabled"  class="pa-4">
                <h4>{{ $t('webinar.details.webinar_scripts_title') }}</h4>
                <span style="font-size: 13px;" :style="`color: ${wsACCENT}`" class="font-weight-regular">
                  {{ $t('webinar.details.webinar_scripts_time') }} {{ finalTime }}
                </span>
                <div style="margin-top: 15px" >
                  <div
                    v-for="(script, index) in webinar.scripts.content"
                    :key="index + script.text"
                    class="rounded px-4 py-2 d-flex align-start justify-start mb-4"
                    style="max-width: 619px; overflow: hidden; border: 1px solid var(--wsBACKGROUND)"
                  >
                    <h5 class="mr-4">{{ script.duration }} {{$t('webinar.settings.minutes_short')}}</h5>
                    <p style="font-size: 13px;" :style="`color: ${wsACCENT}`" class="font-weight-regular mb-0">{{ script.text }}</p>
                  </div>
                </div>
              </div>
              <v-divider v-if="webinar.scripts_enabled"  :style="`color: ${wsBACKGROUND}`"/>

              <div class="pa-4">
                <h4 >{{ $t('webinar.settings.time_before_title') }}</h4>
                <p style="font-size: 13px;" :style="`color: ${wsACCENT}`" class="font-weight-regular mb-0">{{$t('webinar.details.before')}} {{webinar.time_before}} {{$t('webinar.settings.minutes_short')}}</p>
              </div>
              <v-divider :style="`color: ${wsBACKGROUND}`"/>

              <div class="pa-4">
                <h4 >{{ $t('webinar.settings.language_title') }}</h4>
                <p style="font-size: 13px;" :style="`color: ${wsACCENT}`" class="font-weight-regular mb-0"> {{webinar.lang.toUpperCase()}} </p>
              </div>
              <v-divider :style="`color: ${wsBACKGROUND}`"/>

              <div class="pa-4">
                <h4 >{{ $t('webinar.details.show_members_count') }}</h4>
                <p style="font-size: 13px;" :style="`color: ${wsACCENT}`" class="font-weight-regular mb-0">{{ webinar.show_participants ? $t('webinar.details.yes') : $t('webinar.details.no') }}</p>
              </div>

            </v-sheet>
          </template>
          <template #item.record>
            <div class="d-flex align-center justify-center" style="height: 66vh">
              <!--  no record -->
              <div class="text-center">
                <v-icon size="84" :color="wsDARKLIGHT" class="mb-4">mdi-laptop-account</v-icon>
                <h3 :style="`color: ${wsDARKER}`">{{ $t('webinar.details.no_webinar_record') }}</h3>
              </div>

              <!-- record exist -->

            </div>

          </template>
        </ws-navigation>
      </v-sheet>

    </template>


    <template #dialog>

      <!--  Webinar stream dialog   -->
      <webinar-window
          v-if="displayWebinarWindowDialog"
          v-model="displayWebinarWindowDialog"
          :uuid="webinarUuid"
      />

      <!--  Webinar notify dialog    -->
      <ws-prompt-dialog
        :value="displayNotifyDialog"
        :background-color="wsACCENT"
        :text-color="wsWHITE"
        action
        :title="$t('webinar.details.notify_title')"
        :description="$t('webinar.details.notify_description')"
        :button-label="$t('webinar.details.notify_action')"
        width="300px"
        height="fit-content"
        :x="'120px'"
        :y="'170px'"
        @save="saveUserAgreement"
      />

        <!-- Edit Webinar dialog -->
        <webinar-settings
          :only-edit="true"
          :is-new-webinar="false"
          :uuid="webinar.uuid"
          :step="step"
          @set-step="updateStepValue"
          :display-dialog="displayEditDialog"
          @set-display-dialog="displayEditDialog = $event"
          @edit-webinar="webinar = $event"
        />

        <!-- Confirm delete webinar dialog -->
        <ws-dialog
          v-model="displayConfirmDeleteDialog"
          :title="$t(`webinar.main.delete`)"
          :save-text="$t(`Delete`)"
          @save="deleteWebinar(webinar)"
          @cancel="!displayConfirmDeleteDialog"
        >
          <h4  class="text-left font-weight-medium">
            {{ confirmDeleteDialogText }}
            <a
              v-if="webinar.status === 'finished'"
              href="#"
              :style="`color: ${wsATTENTION}; text-decoration: none`"
            >
              {{ $t('webinar.main.dialog.store_link') }}
            </a>
          </h4>
          <template #buttons v-if="webinar.status === 'finished'">
            <div>
              <ws-button :label="$t('Cancel')" outlined class="mr-2" @click="displayConfirmDeleteDialog = false" />
              <ws-button :label="$t(`webinar.main.dialog.delete_with_rec`)" class="mr-2" />
              <ws-button :label="$t(`webinar.main.dialog.delete_only_webinar`)" @click="deleteWebinar(webinar)"/>
            </div>

          </template>
        </ws-dialog>


    </template>

  </dash-page-new>
</template>

<script>

import WebinarWindow from "@modules/webinars/components/Webinar/admin/room/WebinarWindow.vue"
import {mapActions} from "vuex";
import WebinarSettings from "@modules/webinars/components/Webinar/WebinarSettingsDialog.vue";
import WsPromptDialog from "@modules/webinars/components/UI/wsPromptDialog.vue"

export default {
  name: "WebinarDetails.vue",
  components: {
    WsPromptDialog,
    // WebinarWindow,
    WebinarSettings,
    WebinarWindow
  },
  props: {
    webinarUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      limit: 100,
      displayConfirmDeleteDialog: false,
      step: 1,
      displayEditDialog: false,
      displayNotifyDialog: true,
      webinar: {},
      participantsItems: [],
      selectedItem: {},
      displayWebinarWindowDialog: false,
      navigation: 'participants'
    }
  },
  computed: {
    confirmDeleteDialogText() {
      return this.webinar.status === 'finished'
        ? this.$t('webinar.main.dialog.confirm_delete_finished_text')
        : this.$t('webinar.main.dialog.confirm_delete_text')
    },
    scriptsTitle() {
      // вставить общее время заметок как переменную. webinar.script_time_total
      return this.webinar.scripts.scripts_status === 'active' ? `${this.$t('webinar.details.webinar_scripts_time')} 1 год, 10 хв` : `${this.$t('webinar.details.no_scripts')}`
    },

    memberActionsSelect() {
      return [
        { text: this.$t('webinar.details.joined_to_webinar'), value: 'joined' },
        { text: this.$t('webinar.details.not_joined_to_webinar'), value: 'not_joined' },
        { text: this.$t('webinar.details.registered'), value: 'registered' }
      ]
    },
    webinarStatus() {
      switch (this.webinar.status) {
        case 'planned' : return this.$t('webinar.main.planned')
        case 'in_process': return this.$t('webinar.main.in_process')
        default: return this.$t('webinar.main.finished')
      }

    },

    recordColor() {
      switch (this.webinar.status) {
        case 'planned' : return this.wsSUCCESS
        case 'in_process': return this.wsWARNING
        default: return this.wsACCENT
      }
    },
    webinarModerators() {
      return [
        { name: 'Moderator Name', email: 'moderator@gmail.com', phone: '+380123456789', actions: 'Some actions', actionsTime: new Date() }
      ]
    },
    moderatorsHeaders() {
      return this.headers.filter(el => el.value !== 'actions' && el.value !== 'actionsTime')
    },
    // webinarMembers() {
    //   return [
    //     { name: 'User Name', email: 'user@gmail.com', phone: '+380123456789', actions: 'not_joined', actionsTime: new Date()/1000 }
    //   ]
    // },
    headers() {
      return [
        { text : this.$t('webinar.details.contact'), value: 'name' } ,
        { text : this.$t('webinar.details.email' ), value: 'email' },
        { text : this.$t('webinar.settings.member_info.phone'), value: 'phone' },
        { text : this.$t('webinar.details.actions' ), value: 'actions', width: '300px'},
        { text : this.$t('webinar.details.actions_time' ), value: 'actionsTime', width: '10px' },
      ]
    },
    settingsActions() {
      return [
        {
          text : this.$t('webinar.main.table.landing'),
          value : 'landing',
          icon: 'mdi-open-in-new',
          belongs: ['in_process', 'planned'],
          // action : () => {  }
        },
        {
          text : this.$t('webinar.main.table.stop_publishing'),
          value : 'stop_publishing',
          icon: 'mdi-eye-off',
          belongs: ['planned'],
          // action : () => {  }
        },
        {
          text : this.$t('Edit'),
          value : 'edit',
          icon: 'mdi-pencil',
          belongs: ['in_process', 'planned', 'draft'],
          action : () => { this.displayEditDialog = true; }
        },
        {
          text : this.$t('Delete'),
          value : 'delete',
          icon: 'mdi-delete-outline',
          belongs: ['planned', 'finished', 'draft'],
          action : () => { this.displayConfirmDeleteDialog = true;  }
        },
        {
          text: this.$t('webinar.main.tutorial_watch'),
          value: 'video-tutorial',
          icon: 'mdi-video',
          // action : () => {  }
        },
        {
          text: this.$t('webinar.main.create_webinar'),
          value: 'read-tutorial',
          icon: 'mdi-book-variant',
          // action : () => {  }
        },
      ]
    },
    statisticsSelect() {
      return [
        { icon : 'mdi-clock-outline',
          title : this.$t('webinar.details.duration'),
          value : 'duration',
          text : '-',
        },
        { icon : 'mdi-account-multiple',
          title : this.$t('webinar.details.total'),
          value : 'total',
          text : '100',
          text_regular : '1',
        },
        { icon : 'mdi-television-play',
          title : this.$t('webinar.details.accessed'),
          value : 'accessed',
          text : '1',
          text_regular : '1',
        },
        { icon : 'mdi-check-circle-outline',
          title : this.$t('webinar.details.joined'),
          value : 'joined',
          text : '',
          text_regular : '0',
        },
        { icon : 'mdi-alert-octagon-outline',
          title : this.$t('webinar.details.absent'),
          value : 'absent',
          text : '0',
          text_regular : '0',
        },
      ]
    },
    navigationSelect() {
      return [
        { text : this.$t('webinar.settings.members') , value : 'participants' },
        { text : this.$t('webinar.settings.moderators') , value : 'moderators' },
        { text : this.$t('webinar.details.info_about') , value : 'about' },
        { text : this.$t('webinar.details.record') , value : 'record' }
      ]
    },
    finalTime() {
      if (!this.webinar.scripts_enabled) return
      const scripts = [...this.webinar.scripts.content]
      const scriptsFiltered = scripts.filter(el => !el.remove);

      if (!scriptsFiltered.length) {
        return 0
      }

      const generalTime = scriptsFiltered.reduce((accum, curValue) => accum + curValue.duration, 0);
      const hours = Math.floor(generalTime/60);
      const minutes = generalTime%60;
      let timeResult = '';

      if (generalTime > 59) {
        timeResult += `${hours} ${this.$t('webinar.settings.hours_short')}`

        if (minutes !== 0) {
          timeResult += ` ${minutes} ${this.$t('webinar.settings.minutes_short')}`
        }

      } else {
        timeResult += `${generalTime} ${this.$t('webinar.settings.minutes_short')}`
      }
      return timeResult
    },
  },
  methods: {
    ...mapActions('webinars', ['GET_ONE_WEBINAR', 'DELETE_WEBINARS', 'COPY_WEBINAR', 'GET_PARTICIPANTS']),
    saveUserAgreement() {
      console.log('here is code for saving data from prompt dialog')
    },
    async deleteWebinar(item) {
      this.displayConfirmDeleteDialog = true;

      const result = await this.DELETE_WEBINARS(item);

      if (!result) {
        this.ERROR();
        return
      }
      this.$router.back();

      this.notify(this.$t('webinar.notify.delete_webinar'), 'success', 'mdi-check-circle-outline')
      this.displayConfirmDeleteDialog = false;
    },

    updateStepValue(value) {
      this.step = value;
    },

    copyToClipboard() {
      const textLink = 'this is text link copied';
      navigator.clipboard.writeText(textLink);
      this.notify(this.$t('webinar.notify.copy_link'), 'success', 'mdi-check-circle-outline')
    },
    openWebinarWindowDialog() {
      this.displayWebinarWindowDialog = true
    },
    async initPage() {
      const result = await this.GET_ONE_WEBINAR({ uuid: this.webinarUuid } );

      if (!result) {
        this.ERROR();
        return
      }

      this.webinar = await result;

      console.log('this.webinar WEbinarDetails', this.webinar)

      await this.getParticipants()

    },
    async getParticipants() {
      this.loading = true;
      const result = await this.GET_PARTICIPANTS( { uuid: this.webinar.uuid, limit: this.limit } );

      this.loading = false;
      if (!result) {
        this.ERROR();
        return
      }

      console.log(result)

      this.participantsItems = result.items
      this.participantsItems.actions = this.memberActionsSelect

    },

  },
  mounted() {
    this.initPage()
  }
}
</script>



<style scoped lang="scss">
</style>
