<template>
  <v-sheet style="height: 100%; background-color: transparent" class="d-flex flex-column justify-space-between">
    <ws-sidebar-window-header
      :title="$t('webinar.main.preview.banner')"
      @close="$emit('close')"
      :checked-value="checkedValue"
    />

    <v-divider :color="wsACCENT"/>
    <div class="rounded d-flex flex-column justify-space-between" style="height: calc(100% - 60px)">

      <!-- NO BANNER CONTENT  -->
      <div v-if="!isCreateBanner && !isPublishedBanner" class="d-flex align-center justify-center px-7 text-center" style="color: #fff; height: 100%">
        <ws-sidebar-window-no-content
          icon="mdi-gesture-tap-hold"
          :title="$t('webinar.main.preview.banner_title')"
          :subtitle="$t('webinar.main.preview.create_banner_text')"
        >
          <template #action>
            <ws-button left-icon="mdi-plus" :label="$t('webinar.main.preview.create')" @click="createBanner"/>
          </template>
        </ws-sidebar-window-no-content>

      </div>

      <!--   Banner create form  -->
      <div v-if="isCreateBanner" style="color: #fff" class=" pa-4 scrollable">
        <ws-text-field
          v-model="entityDataBanner.title"
          :label="`${$t('webinar.main.preview.banner_title')} *`"
          :placeholder="$t('webinar.main.preview.print_message')"
          area
          required
          label-color="#fff"
          style="color: #fff"
          :style="`border: 1px solid ${wsACCENT}`"
          :max-chars="50"
          class="white-input-text"
        />

        <ws-text-field
          v-model="entityDataBanner.text"
          :label="`${$t('webinar.main.preview.text')} *`"
          :placeholder="$t('webinar.main.preview.print_message')"
          area
          required
          label-color="#fff"
          style="color: #fff"
          :style="`border: 1px solid ${wsACCENT}`"
          :max-chars="50"
          class="white-input-text"

        />

        <ws-text-field
          v-model="entityDataBanner.button_name"
          :label="$t('webinar.main.preview.button_text')"
          :placeholder="$t('webinar.main.preview.buy')"
          class="mb-6 white-input-text"
          label-color="#fff"
          style="color: #fff"
          :style="`border: 1px solid ${wsACCENT}`"
        />

        <ws-text-field
          v-model="entityDataBanner.link"
          :label="`${$t('webinar.main.preview.link')} *`"
          :placeholder="'https://example.com'"
          required
          label-color="#fff"
          style="color: #fff"
          :style="`border: 1px solid ${wsACCENT}`"
          class="white-input-text"
        />

      </div>

      <!--   Published banner  -->
      <div v-if="isPublishedBanner" style="color: #fff; height: 100%" class="d-flex flex-column justify-space-between">
        <div class="pa-4" :style="`background: #3B5365; border-bottom: 1px solid ${wsACCENT}`">
          <h4 class="mb-1">{{ entityDataBanner.title }}</h4>
          <p class="mb-4">{{ entityDataBanner.text }}</p>
          <ws-button :label="entityDataBanner.button_name" icon="mdi-open-in-new" block :href="entityDataBanner.link" :new-window="true"/>
        </div>

        <p class="pl-4" style="font-size: 12px">
          {{ $t('webinar.main.preview.link_opened') }}
          <span style="font-weight: 600">282</span>
        </p>
      </div>

      <v-divider v-if="isCreateBanner" :color="wsACCENT"/>

      <!-- BANNER FOOTER ACTIONS -->
      <ws-sidebar-window-footer
        :hide-buttons="false"
        @cancel="showCancelConfirmDialog"
        @publish="publishBanner"
        @create="stopPublishBanner"
        :is-valid="isValidBannerData"
        :is-create-new="isCreateBanner"
        :no-items="isPublishedBanner"
        :button-label="$t('webinar.main.preview.stop_publish_banner')"
      />
    </div>

    <!--  Confirm stop banner publishing -->
    <ws-dialog
      v-model="displayStopPublishConfirm"
      :title="$t('webinar.main.preview.banner_stop_publish')"
      :cancel-text="$t('GoBack')"
      :save-text="$t('Delete')"
      @save="confirmStopPublish"
    >
      <p>{{ $t('webinar.main.preview.confirm_stop_publish_text') }}</p>
    </ws-dialog>
  </v-sheet>

</template>

<script>
import WsSidebarWindowNoContent
  from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowNoContent.vue";
import WsSidebarWindowFooter from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowFooter.vue";
import WsSidebarWindowHeader from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowHeader.vue";

export default {
  name: "wsSidebarBanner.vue",
  components: {WsSidebarWindowHeader, WsSidebarWindowFooter, WsSidebarWindowNoContent},
  props: {
    checkedValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isCreateBanner: false,
      isPublishedBanner: false,
      entityDataBanner: {},
      displayStopPublishConfirm: false,

    }
  },
  computed: {
    isValidBannerData() {
      const { title, text, link } = this.entityDataBanner;
      const regex = /\b((http|https):\/\/)?([\w\-_]+(\.[\w\-_]+)+)([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?\b/;


      if (!title || !text || !link || String(title).trim() === '' || String(text).trim() === '' || String(link).trim() === '') {
        return false
      }

      if (!regex.test(link)) {
        return false
      }

      return true
    },
  },
  methods: {
    createBanner() {
      this.isCreateBanner = true;
    },
    showCancelConfirmDialog() {
      this.displayCancelConfirm = true
    },
    publishBanner() {
      this.isCreateBanner = false;
      if (!this.entityDataBanner.button_name) {
        this.$set(this.entityDataBanner, 'button_name', this.$t('webinar.main.preview.buy'))
      }
      this.isPublishedBanner = true;


    },
    stopPublishBanner() {
      this.displayStopPublishConfirm = true
    },
    confirmStopPublish() {
      this.isPublishedBanner = false;
      this.isCreateBanner = false;
      this.entityDataBanner = {};
      this.displayStopPublishConfirm = false;
    },
  }
}
</script>

<style scoped lang="scss">
.white-input-text ::v-deep textarea::placeholder {
  color: #9AB2C4!important;
}
.white-input-text ::v-deep input, .white-input-text ::v-deep textarea {
  color: #fff!important;
}
.scrollable {
  overflow-y: scroll;
  height: 100%;

  &::v-deep {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #4E6678;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #4E6678;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
</style>
