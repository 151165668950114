<template>
  <v-menu   offset-overflow >
    <template v-slot:activator="{ on, attrs }">

      <v-hover #default="{hover}">
        <v-sheet
            v-bind="attrs" v-on="on"
            class="text-center pointer px-2 py-1 rounded-lg"
            width="56"
            :color="hover ? '#3C5467' : 'transparent'"
            v-ripple
        >
          <v-icon color="#fff" class="mb-1">mdi-credit-card-chip</v-icon>
          <h6 class="font-weight-regular wsDARKLIGHT" >{{ $t('webinar.main.preview.view') }}</h6>
        </v-sheet>
      </v-hover>
    </template>
    <v-sheet class="pa-2" :color="wsBACKGROUND">
      <v-hover #default="{hover}" v-for="(item,i) in CANVAS_CASCADE_SELECT" :key="i">

        <v-sheet
            @click="localValue = item.value"
            :color="wsACCENT"
            :style="hover || localValue === item.value ? `outline : 2px solid ${wsDARKLIGHT}` : null"
            width="75" height="44"
            class="mb-2 pointer wsRoundedLight"
            v-ripple
        >

          <div v-if="item.value === 'default'"
               class="d-flex align-center justify-center fill-height">
            <v-sheet
                v-for="i in 2"
                :key="`d${i}`"
                :color="wsLIGHTCARD"
                width="32" height="20"
                :class="[{'mr-1' : i === 1}]"
                style="border-radius: 2px"
            />
          </div>

          <div v-if="item.value === 'verticalStretched'"
               class="d-flex align-center justify-center fill-height">
            <v-sheet
                v-for="i in 2"
                :key="`d${i}`"
                :color="wsLIGHTCARD"
                width="32" height="36"
                :class="[{'mr-1' : i === 1}]"
                style="border-radius: 2px"
            />
          </div>

          <div v-if="item.value === 'presentation'"
               class="d-flex align-center justify-center fill-height pa-1"
               style="position:relative;border-radius: 2px"
          >
            <v-sheet class="fill-height" height="100%" width="100%" :color="wsLIGHTCARD" style="position:relative;">
              <v-sheet

                  style="
                    position:absolute;
                    bottom : -4px;
                    right: -4px;
                    width: 24px;
                    height: 18px;
                    z-index: 99;
                    background-color: var(--wsLIGHTCARD);
                    border-radius: 2px;
                    border: 4px solid var(--wsACCENT) !important;
              "
              ></v-sheet>
            </v-sheet>
          </div>

          <div v-if="item.value === 'mainRight'"
               class="d-flex flex-column fill-height pa-1"
               style="position:relative;border-radius: 2px"
          >
            <div class="d-flex justify-center mb-1">
              <div v-for="j in 4" :key="j+'box'"
                   style="background-color: var(--wsLIGHTCARD);height: 8px; width: 12px; border-radius: 2px"
                   :class="[{'mr-1' : j < 4}]">
              </div>
            </div>

            <div class="flex-grow-1 mx-2" style="border-radius: 2px;  background-color: var(--wsLIGHTCARD);">

            </div>
          </div>

          <div v-if="item.value === 'mainTop'"
               class="d-flex flex-row fill-height pa-1"
               style="position:relative;border-radius: 2px"
          >
            <div class="flex-grow-1 mr-1" style="border-radius: 2px;  background-color: var(--wsLIGHTCARD);">

            </div>

            <div class="align-center mb-1 py-2">
              <div v-for="j in 2" :key="j+'box'"
                   style="background-color: var(--wsLIGHTCARD);height: 8px; width: 12px; border-radius: 2px"
                   :class="[{'mb-1' : j < 2}]">
              </div>
            </div>


          </div>


        </v-sheet>

      </v-hover>

    </v-sheet>
  </v-menu>

</template>

<script>
import webinars from "@modules/webinars/mixins/webinars";

export default {
  name: "vmodelComponent",
  mixins: [webinars],
  props : {
    value : {
      type : String
    }
  },
  data() {
    return {
      localValue : null
    }
  },
  watch : {
    localValue() {
      if ( this.value !== this.localValue ) {
        this.$emit('input' , this.localValue)
      }
    },
    value() {
      if ( this.value !== this.localValue ) {
        this.localValue = this.value
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.localValue = this.value
    }
  }
}
</script>

<style scoped>

</style>