var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"d-flex flex-column justify-space-between",staticStyle:{"height":"100%","background-color":"transparent"}},[_c('ws-sidebar-window-header',{staticStyle:{"position":"relative","z-index":"0"},attrs:{"title":_vm.$t('webinar.main.preview.chat'),"is-chat-turned-on":_vm.isChatTurnedOn,"checked-value":_vm.checkedValue,"user":_vm.user},on:{"close":function($event){return _vm.$emit('close')},"set-turned-on":function($event){_vm.isChatTurnedOn = $event}}}),_c('v-divider',{attrs:{"color":_vm.wsACCENT}}),_c('div',{staticClass:"rounded d-flex flex-column justify-space-between flex-grow-1",staticStyle:{"height":"calc(100% - 60px)"}},[(_vm.chatMessages.length === 0 || !_vm.isChatTurnedOn)?_c('div',{staticClass:"d-flex align-center justify-center px-7 text-center",staticStyle:{"color":"#fff","height":"100%"}},[_c('ws-sidebar-window-no-content',{attrs:{"icon":_vm.isChatTurnedOn ? 'mdi-forum-outline' : 'mdi-block-helper',"title":_vm.isChatTurnedOn ? _vm.$t('webinar.main.preview.no_messages') : _vm.$t('webinar.main.preview.chat_turned_off'),"subtitle":_vm.isChatTurnedOn ? _vm.$t('webinar.main.preview.no_messages_text') : _vm.$t('webinar.main.preview.turn_on_chat')}})],1):_c('div',{staticClass:"pa-2 scrollable ",staticStyle:{"color":"#fff","height":"100%"}},_vm._l((_vm.chatMessages),function(message,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var outerHover = ref.hover;
return [_c('div',{staticClass:"d-flex align-start justify-end mb-2",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"pt-2",style:(("padding: 10px 8px; background-color: " + _vm.wsBACKGROUND + "; border-radius: 8px 0px 8px 8px; max-width: 273px; width: fit-content; word-wrap: break-word"))},[_c('p',{style:(("margin: 0; color: " + _vm.wsDARKER))},[_vm._v(_vm._s(message.text))]),_c('p',{style:(("text-align: right; margin: 0; color: " + _vm.wsACCENT))},[_vm._v(" "+_vm._s(message.currentTime)+" ")])]),(outerHover)?_c('div',{staticStyle:{"background-color":"transparent","position":"absolute","right":"0","top":"-47px","z-index":"999","padding-bottom":"8px"}},[_c('div',{key:index + message.text,ref:"content",refInFor:true,staticClass:"d-flex align-center justify-space-between rounded pa-2",staticStyle:{"background-color":"#9AB2C4"}},[_c('ws-tooltip',{staticClass:"mr-2",attrs:{"text":_vm.$t('webinar.main.preview.pin')}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var innerHover = ref.hover;
return [_c('v-icon',{staticClass:"rounded",staticStyle:{"padding":"3px"},style:(("background-color: " + (innerHover ? _vm.wsBACKGROUND : 'transparent'))),attrs:{"size":"24"}},[_vm._v("mdi-pin")])]}}],null,true)})],1),_c('ws-tooltip',{staticClass:"mr-2",attrs:{"text":_vm.$t('webinar.main.preview.forward')}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var innerHover2 = ref.hover;
return [_c('v-icon',{staticClass:"rounded",staticStyle:{"padding":"3px"},style:(("background-color: " + (innerHover2 ? _vm.wsBACKGROUND : 'transparent'))),attrs:{"size":"24"}},[_vm._v("mdi-reply-all")])]}}],null,true)})],1),_c('ws-tooltip',{attrs:{"text":_vm.$t('webinar.main.preview.delete')}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var innerHover3 = ref.hover;
return [_c('v-icon',{staticClass:"rounded",staticStyle:{"padding":"3px"},style:(("background-color: " + (innerHover3 ? _vm.wsBACKGROUND : 'transparent'))),attrs:{"size":"24"}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)]):_vm._e()])]}}],null,true)})}),1),_c('v-divider',{attrs:{"color":_vm.wsACCENT}}),_c('div',{style:(("" + (_vm.isChatTurnedOn ? 'opacity: 1' : 'opacity: 0.5')))},[_c('ws-sidebar-window-footer',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('ws-text-field',{staticClass:"white-input-text mr-10",attrs:{"placeholder":_vm.$t('webinar.main.preview.print_message'),"flat":"","solo":"","background-color":'transparent',"disabled":!_vm.isChatTurnedOn,"default-style":false,"color":"#ffffff"},on:{"input":function($event){_vm.isTextMessage = true}},model:{value:(_vm.entityDataChat.text),callback:function ($$v) {_vm.$set(_vm.entityDataChat, "text", $$v)},expression:"entityDataChat.text"}}),(!_vm.isTextMessage)?_c('ws-tooltip',{staticStyle:{"position":"absolute","top":"16px","right":"16px"},attrs:{"top":"","text":_vm.isChatTurnedOn ? _vm.$t('webinar.main.preview.attach_file') : ''}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{staticClass:"pa-1 rounded",style:(("background-color: " + (hover && _vm.isChatTurnedOn ? _vm.wsACCENT : 'transparent') + "; cursor: " + (_vm.isChatTurnedOn ? 'pointer' : 'auto'))),attrs:{"size":"24","color":"#fff"}},[_vm._v(" mdi-paperclip ")])]}}],null,false,3568916126)})],1):_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{staticClass:"pa-1 rounded",style:(("background-color: " + (hover && _vm.isChatTurnedOn ? _vm.wsACCENT : 'transparent') + "; cursor: " + (_vm.isChatTurnedOn ? 'pointer' : 'auto') + "; position: absolute; top: 16px; right: 16px")),attrs:{"size":"24","color":"#fff"},on:{"click":_vm.sendMessage}},[_vm._v(" mdi-send ")])]}}])})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }