<template>
  <v-sheet style="height: 100%; background-color: transparent" class="d-flex flex-column justify-space-between">
    <!--   Sidebar chat header -->
    <ws-sidebar-window-header
      :title="$t('webinar.main.preview.chat')"
      @close="$emit('close')"
      style="position: relative; z-index: 0"
      :is-chat-turned-on="isChatTurnedOn"
      @set-turned-on="isChatTurnedOn = $event"
      :checked-value="checkedValue"
      :user="user"
    />

    <v-divider :color="wsACCENT"/>

    <div
      class="rounded d-flex flex-column justify-space-between flex-grow-1"
      style="height: calc(100% - 60px); "
    >
      <!--   NO CHAT MESSAGES -->
      <div v-if="chatMessages.length === 0 || !isChatTurnedOn" class="d-flex align-center justify-center px-7 text-center" style="color: #fff; height: 100%">
        <ws-sidebar-window-no-content
          :icon="isChatTurnedOn ? 'mdi-forum-outline' : 'mdi-block-helper' "
          :title="isChatTurnedOn ? $t('webinar.main.preview.no_messages') : $t('webinar.main.preview.chat_turned_off') "
          :subtitle="isChatTurnedOn ? $t('webinar.main.preview.no_messages_text') : $t('webinar.main.preview.turn_on_chat')"
        />
      </div>

      <!--   Block with user messages   -->
      <div v-else style="color: #fff;  height: 100%; " class="pa-2 scrollable ">
        <!--                    scrollable-->

        <v-hover v-slot="{ hover: outerHover }" v-for="(message, index) in chatMessages" :key="index">
          <div  class="d-flex align-start justify-end mb-2" style="position: relative">
            <!--  justify-space-between for other users messages -->
            <!--  Message view (other users)   -->
            <!--                      <v-icon :color="wsACCENT" class="mr-1">mdi-account-circle</v-icon>-->
            <!--                      <div style="padding: 10px 8px; background-color: #3B5365; border-radius: 0 8px 8px 8px; width: 100%; max-width: 245px; word-wrap: break-word">-->
            <!--                        <h5>User Name</h5>-->
            <!--                        <p style="margin: 0">{{ message.text }}</p>-->
            <!--                        <p style="text-align: right; margin: 0"> {{ message.currentTime }} </p>-->
            <!--                      </div>-->

            <!--  My message view   -->
            <div class="pt-2" :style="`padding: 10px 8px; background-color: ${wsBACKGROUND}; border-radius: 8px 0px 8px 8px; max-width: 273px; width: fit-content; word-wrap: break-word`">
              <p :style="`margin: 0; color: ${wsDARKER}`">{{ message.text }}</p>
              <p :style="`text-align: right; margin: 0; color: ${wsACCENT}`"> {{ message.currentTime }} </p>
            </div>

            <!--   Actions menu with message    -->

            <div v-if="outerHover" style="background-color: transparent; position: absolute; right: 0; top: -47px; z-index: 999; padding-bottom: 8px">

              <div
                :key="index + message.text"
                class="d-flex align-center justify-space-between rounded pa-2"
                style="background-color: #9AB2C4; "
                ref="content"
              >
                <ws-tooltip :text="$t('webinar.main.preview.pin')" class="mr-2">
                  <v-hover v-slot="{ hover: innerHover }" >
                    <v-icon size="24" class="rounded" style="padding: 3px" :style="`background-color: ${ innerHover ? wsBACKGROUND : 'transparent' }`">mdi-pin</v-icon>
                  </v-hover>
                </ws-tooltip>
                <ws-tooltip :text="$t('webinar.main.preview.forward')" class="mr-2">
                  <v-hover v-slot="{ hover: innerHover2 }">
                    <v-icon size="24" class="rounded" style="padding: 3px" :style="`background-color: ${ innerHover2 ? wsBACKGROUND : 'transparent' }`">mdi-reply-all</v-icon>
                  </v-hover>
                </ws-tooltip>
                <ws-tooltip :text="$t('webinar.main.preview.delete')">
                  <v-hover v-slot="{ hover: innerHover3 }">
                    <v-icon size="24" class="rounded" style="padding: 3px" :style="`background-color: ${ innerHover3 ? wsBACKGROUND : 'transparent' }`">mdi-delete</v-icon>
                  </v-hover>
                </ws-tooltip>
              </div>
            </div>


          </div>
        </v-hover>


      </div>


      <v-divider :color="wsACCENT"/>

      <!--   Chat window footer -->
      <div
        :style="`${isChatTurnedOn ? 'opacity: 1' : 'opacity: 0.5'}`"
      >
        <ws-sidebar-window-footer>
          <template #input>
            <ws-text-field
              v-model="entityDataChat.text"
              @input="isTextMessage = true"
              :placeholder="$t('webinar.main.preview.print_message')"
              flat
              solo
              :background-color="'transparent'"
              :disabled="!isChatTurnedOn"
              :default-style="false"
              color="#ffffff"
              class="white-input-text mr-10"

            />
            <ws-tooltip
              v-if="!isTextMessage"
              top
              :text="isChatTurnedOn ? $t('webinar.main.preview.attach_file') : ''"
              style="position: absolute; top: 16px; right: 16px"
            >
              <v-hover v-slot="{hover}" >
                <v-icon
                  size="24"
                  color="#fff"
                  class="pa-1 rounded"
                  :style="`background-color: ${hover && isChatTurnedOn ? wsACCENT : 'transparent'}; cursor: ${ isChatTurnedOn ? 'pointer' : 'auto'}`"
                >
                  mdi-paperclip
                </v-icon>
              </v-hover>
            </ws-tooltip>
            <v-hover v-slot="{hover}" v-else >
              <v-icon
                size="24"
                color="#fff"
                class="pa-1 rounded"
                :style="`background-color: ${hover && isChatTurnedOn ? wsACCENT : 'transparent'}; cursor: ${ isChatTurnedOn ? 'pointer' : 'auto'}; position: absolute; top: 16px; right: 16px`"
                @click="sendMessage"
              >
                mdi-send
              </v-icon>
            </v-hover>
          </template>
        <!--   Sidebar chat footer   -->
        </ws-sidebar-window-footer>

      </div>
    </div>
  </v-sheet>

</template>


<script>
import WsSidebarWindowNoContent
  from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowNoContent.vue";
import WsSidebarWindowFooter from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowFooter.vue";
import WsSidebarWindowHeader from "@modules/webinars/components/Webinar/admin/room/sidebars/wsSidebarWindowHeader.vue";

export default {
  name: "wsSidebarChat.vue",
  components: {WsSidebarWindowHeader, WsSidebarWindowFooter, WsSidebarWindowNoContent},
  props: {
    user: {
      type: Boolean,
      default: false
    },
    checkedValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chatMessages: [],
      isChatTurnedOn: true,
      entityDataChat: {},
      isTextMessage: false,
    }
  },
  methods: {
    sendMessage() {
      this.chatMessages.push(this.entityDataChat)
      this.countCurrentTime();
      this.entityDataChat = {}
      this.isTextMessage = false

    },
    countCurrentTime() {
      const date = new Date();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      this.entityDataChat.currentTime =  `${hours}:${minutes}`
    },

  }
}
</script>

<style scoped lang="scss">
.white-input-text ::v-deep textarea::placeholder {
  color: #9AB2C4!important;
}
.white-input-text ::v-deep input, .white-input-text ::v-deep textarea {
  color: #fff!important;
}
.scrollable {
  overflow-y: scroll;
  height: 100%;

  &::v-deep {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #4E6678;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #4E6678;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
</style>
