<template>
    <div
      class="d-flex align-center justify-space-between px-4"
      style="max-height: 60px; padding-top: 15px; padding-bottom: 15px "
    >
      <v-hover v-if="isFullSurvey" v-slot="{ hover: titleHover}">
        <div class="d-flex align-center">
          <v-icon v-if="isFullSurvey" class="mr-1" :color="titleHover ? wsWHITE : wsACCENT">mdi-chevron-left</v-icon>
          <h5
            style="margin-right: 10px"
            :style="`color: ${isFullSurvey && !titleHover ? wsACCENT : wsWHITE}; cursor: ${titleHover ? 'pointer' : 'auto'}`"
            @click="$emit('back')"
          >
            {{ !isFullSurvey ? $t('webinar.main.preview.questions') : $t('GoBack') }}
          </h5>
        </div>
      </v-hover>

      <div v-else class="d-flex align-center justify-space-between">
        <h5 style="margin-right: 10px" :style="`color: ${wsWHITE}`" >{{ title }}</h5>
        <ws-tooltip v-if="checkedValue === 'chat' && !user" :text="isChatTurnedOn ? $t('webinar.main.preview.chat_on') : $t('webinar.main.preview.chat_off')">
          <ws-switch class="d-block" v-model="isTurnedOn" style="height: 24px; object-fit: contain"></ws-switch>
        </ws-tooltip>
      </div>


      <v-hover v-slot="{hover}">
        <ws-tooltip :text="$t('Close')">
          <v-icon
            size="24"
            @click="$emit('close')"
            color="#fff"
            :style="`background-color: ${hover ? wsACCENT : 'transparent'}`"
            style="padding: 4px"
            class="rounded"
          >
            mdi-close
          </v-icon>
        </ws-tooltip>
      </v-hover>
    </div>
</template>

<script>
export default {
  name: "wsSidebarWindowHeader.vue",
  props: {
    user: {
      type: Boolean,
      default: false
    },
    checkedValue: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    isActiveChat: {
      type: Boolean,
      default: true
    },
    isChatTurnedOn: {
      type: Boolean,
      default: true
    },
    isFullSurvey: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTurnedOn: true
    }
  },
  watch: {
    isTurnedOn(val) {
      this.$emit('set-turned-on', val)
    }
  }
}
</script>

<style scoped>
.v-application::v-deep {
  margin-top: 0!important
}
.v-input--selection-controls::v-deep {
  margin-top: 0!important
}
</style>
