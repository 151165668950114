function drawMainSource(context,source,canvas) {
    const padding = 12;
    const ratio = canvas.width / canvas.height;
    const secondaryArea = canvas.height/4 + padding
    const heightDifference = secondaryArea + (padding*2)
    const height = canvas.height - heightDifference
    const width = canvas.width - Math.ceil(heightDifference*ratio)

    const xCenterRemark = (canvas.width - width)/2

    let x =  xCenterRemark
    let y =  secondaryArea

    context.drawImage(source.dom, x, y, width, height)
}

function drawSecondarySource(context,sources,canvas) {

    let sourcesCount = sources.length

    if (sourcesCount > 4) {
        sourcesCount = 4
    }

    for (let index = 0; index < sourcesCount; index++ ) {

        const source = sources[index];

        const padding = 12;

        const width = canvas.width/4 - (padding*1.5)
        const height = canvas.height/4 - (padding*1.5)

        const totalSourcesWidth = (sourcesCount*width) + (padding*(sourcesCount - 1))
        const xCenterRemark = (canvas.width - totalSourcesWidth)/2


        let y = padding
        let x = xCenterRemark
        if (index > 0 ) {
            x += (width + padding)*index
        }

        context.drawImage(source.dom, x, y, width, height)
    }

}

function drawCanvas(context,sources,canvas) {

    let mainSource = sources.find(el => el.main)
    let secondarySources = sources.filter(el => !el.main)

    if (!mainSource) {
        mainSource = Object.assign({}, sources[0])
        secondarySources.pop()
    }

    if (mainSource) {
        drawMainSource(context,mainSource,canvas)
    }
    if (secondarySources) {
        drawSecondarySource(context,secondarySources,canvas)
    }

}

export default {
    drawCanvas : drawCanvas
}