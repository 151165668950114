<template>
  <draggable
    v-model="entityData"
    :forceFallback="true"
    :empty-insert-threshold="120"
    handle=".tableDragHandle"
    v-bind="opt"
    @start="drag = true"
    @end="drag = false; finishDrag($event)"
  >

    <div v-for="(item, index) in entityData" :key="item.uuid" class="mb-4" >

      <div v-if="!item.remove" class="d-flex align-center justify-space-between pa-2 pr-6 rounded-lg" :style="`background-color: ${wsLIGHTCARD}`">
        <v-icon  :style="`cursor:${ !drag ? 'grab' : 'grabbing' }`" class="tableDragHandle mr-2" >mdi-drag-vertical</v-icon>
        <div style="width: 95%">
          <div class="d-flex align-center justify-space-between" >
            <ws-select
              background-color="transparent"
              flat
              solo
              v-model="item.duration"
              style="max-width: 100px"
              :items="lectureScriptTimeSelect"
              hide-details
            />

            <v-icon :style="`color: ${wsACCENT}; cursor: pointer`" @click="deleteScriptItem(index)">mdi-delete-outline</v-icon>

          </div>
          <v-divider class="my-3"/>
          <ws-text-field
            background-color="transparent"
            flat
            solo
            v-model="item.text"
            :placeholder="$t('webinar.settings.script_comment')"
          />
        </div>
      </div>
    </div>
    <ws-button :label="$t('webinar.settings.add_notes')" @click="addNewScriptNote" class="mb-5"/>
  </draggable>



</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "wsDraggableNote.vue",
  components: {draggable},
  props: {
    scripts: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      entityData: [{ text: ``, duration: 10 }],
      drag: false,
      opt: {
        animation: 200,
        ghostClass: "ghost"
      },
    }
  },
  computed: {
    // создается массив с указанием времени с интервалом 5 минут (для сценариев)
    lectureScriptTimeSelect() {
      let timeArray = [];
      for (let i = 5; i < 65; i += 5) {
        timeArray.push({text: `${i} ${this.$t('webinar.settings.minutes_short')}`, value: i})
      }
      return timeArray
    },
  },
  methods: {
    finishDrag() {
      this.$emit('update-script-items', [...this.entityData])
      this.entityData = this.COPY(this.entityData)
    },

    deleteScriptItem(index) {
      // добавляю ключ remove=true удаляемым элементам
      this.entityData = this.entityData.map((el, i) => i === index ? { ...el, remove: true } : el)

      this.$emit('update-script-items' , [...this.entityData]);

      if (this.entityData.every(el => el.remove)) {
        this.$emit('update-add-script', false)
      }

    },
    addNewScriptNote() {
      this.entityData.push({ text: ``, duration: 10 })
    },
  },
  mounted() {
    this.entityData = [...this.scripts]
  },
  watch: {
    entityData: {
      handler(val) {
        this.$emit('update-script-items' , this.COPY([...val]))
      },
      deep : true

    },

  },


}

</script>


<style scoped>

</style>
