<template>
  <v-sheet color="transparent" class="d-flex justify-space-between" style="height: 100%">

    <!--  CONTENT  -->
    <ws-right-sidebar-content
      class="mr-3 fill-height"
      :user="user"
      :checked-value="checkedValue"
      :is-active-sidebar-window="isActiveSidebarWindow"
      @close="isActiveSidebarWindow = $event"
    />

    <!--  NAVIGATION  -->
    <ws-right-sidebar-navigation
      v-if="user && checkedValue === '' || !user"
      :user="user"
      class="align-self-center"
      :loading="loading"
      :content="isActiveSidebarWindow"
      @value="checkedValue = $event"
      @open="isActiveSidebarWindow = $event"
    />
  </v-sheet>

</template>

<script>
import WsRightSidebarNavigation from "@/modules/webinars/components/Webinar/admin/room/sidebars/wsRightSidebarNavigation.vue"
import WsRightSidebarContent from "@/modules/webinars/components/Webinar/admin/room/sidebars/wsRightSidebarContent.vue"

export default {
  name: "wsRightSidebarMenu.vue",
  components: {WsRightSidebarContent, WsRightSidebarNavigation},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    sidebarWindow: {
      type: Boolean,
      default: false
    },
    user: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      checkedValue: '',
      isActiveSidebarWindow: false
    }
  },
  watch: {
    isActiveSidebarWindow(val) {
      !val ? this.checkedValue = '' : false
    }
  }
}
</script>

<style scoped>

</style>
