<template>
<div>

  <v-hover #default="{hover}" class="mb-2">

    <div :style="rowStyle" class="mx-2 wsRoundedSemi">
      <!-- Pariticipan main info row: avatar, name, dots  -->
      <div
          :class="[{offline : !item.is_online }]"
          class="d-flex justify-space-between align-center px-2 py-1">
        <div class="d-flex pointer align-center">
          <img v-if="item.avatar" :src="item.avatar" class="mr-2 rounded-circle" style="width: 32px; height: 32px">
          <div v-else :style="`background-color: ${item.is_requesting_stream ? wsDARKER : wsACCENT};width: 32px; height: 32px; padding: 4px 3px`"
               class="rounded-circle d-flex align-center justify-center mr-2">
            <h5 :style="`color: ${wsBACKGROUND}`">{{ getInitials(item.name || '') }}</h5>
          </div>

          <div>
            <h5 :class="item.is_online ? 'wsWHITE' : 'wsDARKLIGHT'">{{ item.name }}</h5>
          </div>

        </div>

        <!-- Actions-->
        <div>
          <v-icon :color="wsWHITE" v-if="hover && item.is_online">mdi-dots-horizontal</v-icon>
          <v-icon v-if="!item.is_online" :color="wsWHITE">mdi-cloud-off-outline</v-icon>
        </div>
      </div>

      <div v-if="item.is_requesting_stream">
        <div class="d-flex pa-2">
          <v-row no-gutters>
            <v-col cols="6" class="pr-1">
              <ws-button @click="replyStudentStreamRequest(false)" label="Deny" block :color="wsLIGHTCARD" outlined></ws-button>
            </v-col>
            <v-col class="pl-1">
              <ws-button @click="replyStudentStreamRequest(true)" label="Allow" block></ws-button>
            </v-col>
          </v-row>


        </div>
      </div>

    </div>

  </v-hover>

</div>
</template>


<script>
import {mapActions} from "vuex";
import webinars from "@modules/webinars/mixins/webinars";

export default {
  name: "webinarParticipant",
  mixins: [webinars],
  props : {
    item : {
      type : Object,
      default() {
        return {};
      }
    }
  },
  computed : {
    rowStyle() {
      let style = ''
      if (this.item.is_requesting_stream) {
        style += `background-color : ${this.wsACCENT} !important;`
        style += `padding-top:4px !important;`
      }
      return style
    }
  },
  methods : {
    ...mapActions('stream',
        ['SEND_STREAM_SOCKET_DATA']
    ),


    async replyStudentStreamRequest(permission = false) {

      let data = {
        type : 'student_stream_request_reply',
        receivers : [this.item.user],
        permission : permission,
        webinar : this.WEBINAR.uuid,
      }
      let result = await this.SEND_STREAM_SOCKET_DATA(data)
      if (!result) {
        return this.ERROR()
      }

      this.item.is_requesting_stream = false
      this.EDIT_PARTICIPANT(this.item)


    },

    hashCode(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
      }
      return Math.abs(hash);
    },
    getInitials(str) {
      // Split the string into words
      const words = str.trim().split(/\s+/);

      if (words.length === 1) {
        // If there's only one word, return the first two letters of that word
        return words[0].slice(0, 2).toUpperCase();
      } else {
        // If there are multiple words, return the first letter of the first two words
        return (words[0][0] + words[1][0]).toUpperCase();
      }
    }
  }
}
</script>

<style scoped>
.offline {
  opacity: 0.3 !important;
}
</style>