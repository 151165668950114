function drawMainSource(context,source,canvas) {
    const padding = 12;
    const secondaryArea = (canvas.width/4 - (padding*1.5)) + padding

    const width = canvas.width - secondaryArea - (padding*2)
    const height = canvas.height - (padding*2)
    const sourceX = (canvas.width - width)/2

    let x = padding
    let y =  padding

    context.drawImage(source.dom, sourceX,0,width,canvas.height, x, y, width, height)
}

function drawSecondarySource(context,sources,canvas) {

    let sourcesCount = sources.length

    if (sourcesCount > 4) {
        sourcesCount = 4
    }

    for (let index = 0; index < sourcesCount; index++ ) {

        const source = sources[index];
        const padding = 12;

        const secondaryArea = (canvas.width/4 - (padding*1.5)) + padding


        const width = canvas.width/4 - (padding*1.5)
        const height = canvas.height/4 - (padding*1.5)

        const totalSourcesHeight = (sourcesCount*height) + (padding*(sourcesCount - 1))
        const yCenterRemark = (canvas.height - totalSourcesHeight)/2


        let x = canvas.width - secondaryArea
        let y = yCenterRemark
        if (index > 0 ) {
            y += (height + padding)*index
        }

        context.drawImage(source.dom, x, y, width, height)
    }

}

function drawCanvas(context,sources,canvas) {

    let mainSource = sources.find(el => el.main)
    let secondarySources = sources.filter(el => !el.main)

    if (!mainSource) {
        mainSource = Object.assign({}, sources[0])
        secondarySources.pop()
    }

    if (mainSource) {
        drawMainSource(context,mainSource,canvas)
    }
    if (secondarySources) {
        drawSecondarySource(context,secondarySources,canvas)
    }

}

export default {
    drawCanvas : drawCanvas
}