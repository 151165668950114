<template>
  <v-sheet
    class="pa-1 rounded-lg"
    style="background-color: #31485A;"
  >
    <v-hover
      v-slot="{ hover }"
      v-for="(item, index) in sidebarMenu"
      :key="item.value"
    >
      <v-skeleton-loader
        :loading="loading"
        type="card-avatar"
        width="58px"
        :class="{ 'skeleton-controls' : loading }"
        class="rounded-lg mb-1"
        height="58px"
      >
        <div
          v-ripple
          class="pointer text-center py-2 px-4 rounded-lg"
          :style="{ 'background-color': (hover ? '#9AB2C415' : (checkedItemIndex === index ? '#9AB2C440' : 'transparent')) }"
          @click="() => { checkedItemIndex !== index ? openSidebarDialog(item, index) : false }"
        >

          <v-icon
            :color="`${ hover || checkedItemIndex === index ? '#fff' : wsACCENT }`"
            style="margin-bottom: 6px"
          >
            {{ item.icon }}
          </v-icon>

          <h6 :ref="item.value" :style="`color: ${ hover || checkedItemIndex === index ? '#fff' : wsACCENT };`">{{ item.text }}</h6>
        </div>
      </v-skeleton-loader>
    </v-hover>
  </v-sheet>
</template>

<script>
export default {
  name: "wsRightSidebarNavigation.vue",
  props: {
    content: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    user: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      checkedItemIndex: ''
    }
  },
  computed: {
    sidebarMenu() {
      return this.user ? this.sidebarMenuItems.filter(el => el.value === 'chat') : this.sidebarMenuItems
    },
    sidebarMenuItems() {
      return [
        {
          text: this.$t('webinar.main.preview.chat'),
          value: 'chat',
          icon: 'mdi-message',
        },
        {
          text: this.$t('webinar.main.preview.notes'),
          value: 'scripts',
          icon: 'mdi-format-list-bulleted',
        },
        {
          text: this.$t('webinar.main.preview.people'),
          value: 'participants',
          icon: 'mdi-account-multiple-outline',
        },
        {
          text: this.$t('webinar.main.preview.surveys'),
          value: 'surveys',
          icon: 'mdi-chart-box',
        },
        {
          text: this.$t('webinar.main.preview.banner'),
          value: 'banner',
          icon: 'mdi-gesture-tap',
        },
      ]
    },
  },
  methods: {
    openSidebarDialog(item, index) {
      this.checkedItemIndex = (this.checkedItemIndex === index ? null : index);
      this.$emit('value', this.sidebarMenuItems[index].value);
      this.$emit('open', true);
    },
  },
  watch: {
    content(value) {
      if (!value) {
        this.checkedItemIndex = -1
      }
    }
  }
}
</script>

<style scoped>

</style>
