<template>
  <v-sheet color="#31485A" class="d-flex flex-column fill-height wsRoundedSemi">

    <!-- Main Video View -->
    <div class="flex-grow-1 px-3 pt-3">
      <webinar-video v-if="!user"/>
      <webinar-video-student v-else />
    </div>

    <!-- Video Controls-->
    <div class="py-6 pr-6">
      <webinar-video-controls :user="user ? user : false" />
    </div>


  </v-sheet>
</template>
<script>

import webinarVideoControls from "@modules/webinars/components/Webinar/admin/room/stream/webinarVideoControls.vue"
import webinarVideo from "@modules/webinars/components/Webinar/admin/room/webinarVideo.vue"
import webinars from "@modules/webinars/mixins/webinars";
import WebinarVideoStudent from "@modules/webinars/components/student/webinar-room/webinarVideoStudent.vue";
export default {
  name: "webinarVideoView",
  mixins: [webinars],
  components : {
    WebinarVideoStudent,
    webinarVideo,
    webinarVideoControls
  },
  props: {
    user: {
      type: Boolean,
      default: false
    }
  }
}
</script>


<style scoped>

</style>
