let cascades = {};
const requiredPlugins = require.context('@modules/webinars/plugins/streamCanvasBuilder/cascadeSchemes', false,  /\.cascade\.js$/);

requiredPlugins.keys().forEach(filename => {

    const cascadeName = filename
        .replace(/(\.\/|\.cascade\.js)/g, '')
    cascades[cascadeName] = requiredPlugins(filename).default || requiredPlugins(filename);
});

export default cascades;