<template>
  <ws-dialog
      v-model="displayDialog" width="600"
      :title="$t( title || 'AddNewClient')"
      @save="save"
      fullscreen
      fullscreen-width="400"
      not-centered
      fullscreen-align="right"
      :loading="LOADING"
      :disable-save="disableSaveCondition"
  >
    <ws-text-field
        v-model="entityData.name"
        :label="$t('Firstname')"
        :placeholder="$t('EnterFirstname')"
        :error="nameError"
        @input="nameError = false"
        avalon-style
    />
    <ws-text-field
        class="mt-5"
        v-model="entityData.lastname"
        :label="$t('Lastname')"
        :placeholder="$t('EnterLastname')"
        avalon-style
    />
    <ws-text-field
        class="mt-5"
        v-model="entityData.password"
        :label="$t('Password')"
        :placeholder="$t('EnterPassword')"
        :error="passwordError"
        @input="passwordError = false"
        avalon-style
    />
    <ws-text-field
        class="mt-5"
        v-model="entityData.email"
        :placeholder="$t('Email')"
        :label="$t('Email')"
        :error="emailError || userError"
        @input="emailError = false ; userError = false"
        avalon-style
    />
    <ws-phone-input
        class="mt-5"
        :label="$t('Phone')"
        :error="phoneError || userError"
        @input="phoneError=false ; userError = false "
        v-model="entityData.phone"
        hide-details
        avalon-style
    />
    <ws-date-picker
        class="mt-5"
        v-model="entityData.birthday"
        :label="$t('Birthday')"
        :placeholder="$t('ChooseDate')"
        avalon-style
    />
    <ws-text-field
        class="mt-5"
        v-model="entityData.country"
        :placeholder="$t('Country')"
        :label="$t('Country')"
        avalon-style
    />

    <slot name="default"></slot>

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "wsUserEditDialog",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    display : {
      type : Boolean,
      default : false
    },
    title : {
      type : String,
    },
    apiError: {
      type : String
    }
  },
  data() {
    return {
      entityData : {},
      displayDialog : false,

      nameError : false,
      passwordError : false,
      emailError : false,
      userError : false,
      phoneError : false,

      reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ ,

    }
  },
  computed : {
    disableSaveCondition() {
      if ( !this.entityData.email ) {
        return true
      }

      if ( !this.entityData.phone ) {
        return true
      }

      if ( !this.entityData.name ) {
        return true
      }

      if ( !this.entityData.password ) {
        return true
      }

      return false
    }
  },
  watch : {
    entityData : {
      handler (val) {
        if (!this.EQ(val , this.value)) {
          this.$emit('input' , this.COPY(val))
        }
      },
      deep : true
    },
    value : {
      handler (val) {
        if (!this.EQ(val , this.entityData)) {
          this.entityData = this.COPY(val)
        }
      },
      deep : true
    },
    display() {
      if ( this.display !== this.displayDialog) {
        this.displayDialog = this.display
      }
    },
    displayDialog() {
      this.flushErrors()
      if ( this.display !== this.displayDialog && !this.displayDialog) {
        this.$emit('close' , this.displayDialog)
      }
    }
  },
  methods : {
    ...mapActions('auth', ['ADD_PHONE_NUMBER' , 'CHECK_EMAIL']),

    flushErrors() {
          this.nameError     = false
          this.passwordError = false
          this.emailError    = false
          this.userError     = false
          this.phoneError    = false
    },
    async save() {
      if ( !this.entityData.email) {
        this.emailError = true
        return this.notify(this.$t('EnterEmail'),'warning')
      }
      if(!this.reg.test(this.entityData.email)  ) {
        this.emailError = true
        this.notify(this.$t('WrongEmail'),'warning');
        return
      }

      if ( !await this.CHECK_EMAIL({ email : this.entityData.email }) ) {
        this.emailError = this.$t("UserAlreadyExists");
        return this.notify(this.$t('UserAlreadyExists') , 'warning')
      }

      if ( !this.entityData.password) {
        this.passwordError = true
        return this.notify(this.$t('EnterPassword'),'warning')
      }

      if ( !this.entityData.phone) {
        this.phoneError = true
        return this.notify(this.$t('EnterPhone'),'warning')
      }

      if ( !this.entityData.name) {
        this.nameError = true
        return this.notify(this.$t('EnterFirstname'),'warning')
      }

      this.entityData.email = this.entityData.email.replace(/ /g,'');

      this.$emit('save' )
    }
  },
  mounted() {
    this.entityData = this.COPY(this.value);
    this.displayDialog = this.display
  }

}
</script>


<style scoped>

</style>