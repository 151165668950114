/**
 * @param {CanvasRenderingContext2D} context - The canvas rendering context.
 * @param {Array} sources - The sources to draw on the canvas.
 * @param {HTMLCanvasElement} canvas
 */
function drawVerticalSplit(context,sources,canvas) {

    let sourcesCount = sources.length

    for (let index in sources ) {
        const source = sources[index];

        const width = canvas.width/sourcesCount
        const height = canvas.height

        let x = 0
        let y =  0
        if (index > 0 ) {
            x = (canvas.width/sourcesCount)*index
        }

        const sourceX = (canvas.width-width)/2

        context.drawImage(source.dom, sourceX, 0, width ,canvas.height , x,y, width, height)
    }

}
function drawQuadro(context,sources,canvas) {

    let sourcesCount = sources.length

    if (sourcesCount > 4) {
        sourcesCount = 4
    }

    let gridSize = Math.ceil(Math.sqrt(sourcesCount));
    let rowsWithVideo = Math.ceil(sourcesCount/gridSize);
    let colsWithVideoRemain = sourcesCount%gridSize

    for (let index = 0; index < sourcesCount; index++) {
        const source = sources[index];

        const row = Math.floor(index / gridSize);
        const column = index % gridSize;

        const width = canvas.width / gridSize
        const height = canvas.height / gridSize

        const totalSourcesHeight = rowsWithVideo*height
        const yCenterRemark = (canvas.height - totalSourcesHeight)/2

        let y =  row * height
        y += yCenterRemark

        let x =  column*width;

        if ( rowsWithVideo === (row + 1) && colsWithVideoRemain > 0  ) {

            const totalSourcesWidth = colsWithVideoRemain*height
            const xCenterRemark = (canvas.width - totalSourcesWidth)/2
            x += xCenterRemark
        }

        context.drawImage(source.dom, x, y, width, height);
    }

}

function drawCanvas(context,sources,canvas) {

    const sourcesCount = sources.length

    switch (sourcesCount) {
        case 2 : drawVerticalSplit(context,sources,canvas); return
        case 3 : drawVerticalSplit(context,sources,canvas); return
        default : drawQuadro(context,sources,canvas); return
    }

}

export default {
    drawCanvas : drawCanvas
}