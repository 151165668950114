<template>
  <ws-dialog
    v-model="display"
    width="276px"
    height="276px"
    persistent
    no-padding
    not-centered
    hide-buttons
    no-navigation
    blank
    background-color="transparent"
    border-radius="150"
  >

    <template #default >
      <v-sheet
        style="width: 100%; height: 100%; border-radius: 50%; overflow: hidden"
        :style="`background-color: ${wsLIGHTCARD}40`"
        class="d-flex align-center justify-center countdown"
      >
        <!--  countdown before start  -->
        <transition name="flip" mode="out-in">
          <h2 :style="`font-size: 96px; color: ${wsWHITETEXT}`"  :key="counter" class="digit">
            {{counter !== 0 ? counter : ''}}
          </h2>
        </transition>
      </v-sheet>
    </template>

  </ws-dialog>
</template>

<script>

export default {
  name: "wsCountdownBeforeStart.vue",
  props: {
    value : {
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      display : false,
      counter: 3,
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods: {

    countdownBeforeStart() {
      let digit = this.counter;
      const intervalId = setInterval(() => {
        digit--;

        if (digit > 0) {
          this.counter = digit;
        } else {
          clearInterval(intervalId);
          this.counter = 0;
          this.$store.state.stream.startStreamTrigger++
          this.notify(this.$t('webinar.notify.webinar_started'), 'success', 'mdi-check-circle-outline');
          this.display = false
        }
      }, 1000)
    },
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
    this.countdownBeforeStart()
  },

}
</script>

<style scoped lang="scss">
// countdown animation
.flip-enter-active, .flip-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}

.flip-enter {
  transform: translateY(100%);
  opacity: 0;
}

.flip-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.flip-leave {
  transform: translateY(0);
  opacity: 1;
}

.flip-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
