<template>
	<div
		class="pb-2 pt-2 pr-6 pl-6 d-flex align-center justify-space-between "
    style="background-color: #445D70; column-gap: 38px"
	>
    <v-skeleton-loader
      :loading="loading"
      type="card-avatar"
      :class="{ 'skeleton-bg' : loading }"
      height="32px"
      class="rounded-lg"
    >
      <h4 class="wsWHITETEXT" > {{ WEBINAR.name || '' }}</h4>
    </v-skeleton-loader>

    <div class="d-flex justify-space-between align-center flex-shrink-0" style="gap: 24px">
      <div class="d-flex justify-space-between align-center" style="gap: 16px">

        <!--  Countdown element    -->
        <v-skeleton-loader
          :loading="loading"
          type="card-avatar"
          width="32px"
          :class="{ 'skeleton-bg small' : loading }"
          class="rounded-lg"
          height="32px"
        >
          <ws-count-down />
        </v-skeleton-loader>

        <v-skeleton-loader
          :loading="loading"
          type="card-avatar"
          width="fit-content"
          :class="{ 'skeleton-bg small' : loading }"
          class="rounded-lg"
          height="32px"
        >
          <ws-tooltip :text="$t('webinar.main.preview.settings')">
            <v-btn icon :color="wsWHITETEXT"><v-icon>mdi-cog-outline</v-icon></v-btn>
          </ws-tooltip>
        </v-skeleton-loader>

        <v-skeleton-loader
          :loading="loading"
          type="card-avatar"
          width="fit-content"
          :class="{ 'skeleton-bg small' : loading }"
          class="rounded-lg"
          height="32px"
        >
          <div class="d-flex justify-space-between align-center" style="column-gap: 8px">
            <v-btn icon :color="wsWHITETEXT"><v-icon>mdi-account-multiple-outline</v-icon></v-btn>
            <h5 class="wsWHITETEXT">{{ ONLINE_PARTICIPANTS_COUNT }}</h5>
          </div>
        </v-skeleton-loader>

      </div>

      <!-- Header action buttons  -->
      <div class="d-flex justify-space-between align-center" style="column-gap: 8px">
        <v-skeleton-loader
          :loading="loading"
          type="card-avatar"
          width="fit-content"
          :class="{ 'skeleton-bg small' : loading }"
          class="rounded-lg"
          height="32px"
        >
          <ws-button
            outlined
            :label="$t(!IS_ON_AIR ? 'webinar.main.preview.start' : 'webinar.main.preview.stop')"
            :color="wsWHITETEXT"
            @click="$emit('start')"
          />
        </v-skeleton-loader>
        <v-skeleton-loader
          :loading="loading"
          type="card-avatar"
          width="fit-content"
          :class="{ 'skeleton-bg small' : loading }"
          class="rounded-lg"
          height="32px"
        >
          <ws-button
            icon="mdi-exit-to-app"
            :color="`${wsWARNING}`"
            min-width="56px"
            @click="$emit('close')"
          />
        </v-skeleton-loader>
      </div>
    </div>

	</div>
</template>

<script>

import WsCountDown from "@modules/webinars/components/Webinar/admin/room/UI/header/wsCountDown.vue";
import webinars from "@/modules/webinars/mixins/webinars";

export default {
  name: "wsWebinarDialogHeader.vue",
  mixins: [webinars],
  components: {
      WsCountDown
  },
  computed : {
    loading() {
      return !this.WEBINAR.uuid
    }
  }

}
</script>

<style scoped>
.skeleton-bg {
  width: 100%;
  border-radius: 26px;
  background: linear-gradient(90deg, rgba(154, 178, 196, 0.52) 0.59%, rgba(154, 178, 196, 0.32) 36.12%, rgba(154, 178, 196, 0.00) 118.43%);

}
.skeleton-bg small {
  background: linear-gradient(90deg, rgba(154, 178, 196, 0.52) 0.55%, rgba(154, 178, 196, 0.32) 33.6%, rgba(154, 178, 196, 0.00) 110.16%);
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>
